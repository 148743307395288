<template>
  <div class="profile-settings">
    <form class="profile-settings__form" @submit.prevent>
      <div class="profile-settings__row">
        <Input label="Мобильный телефон" initialValue="+7 (702) 620 74 47" locked disabled />
      </div>
      <div class="profile-settings__button">
        <Button title="Сохранить" color="primaryGreen" width="100%" @click="saveSettings" />
        <Button v-if="isMobile" title="Выйти" color="primaryRed" width="100%" :isImageFirst="true" @click="logOut" >
          <template #icon>
            <img src="@/assets/icons/mobile/exit.svg" alt="exit">
          </template>
        </Button>
      </div>
    </form>
  </div>
</template>

<script>
import authService from "@/services/auth.service";
import Button from "@/components/base/button/Button.vue";
import {mediaQuery} from "@/utils/helpers/mediaQuery";

export default {
  name: 'Profile',
  components: {
    Button,
  },
  data() {
    return {
      isMobile: mediaQuery('(max-width: 900px)'),
    };
  },
  methods: {
    logOut() {
      authService.logout()
      this.$router.push('/');
    },
    saveSettings(e) {
      e.preventDefault();
    }
  }
};
</script>

<style lang="scss">
.profile-settings {
  display: flex;
  padding: 14px 28px;

  &__form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__row {
    display: flex;
    gap: 28px;
  }

  &__button {
    width: calc(50% - 14px);

    @media (max-width: 900px) {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 8px;
    }
  }
}
</style>
