<template>
  <div class="favorite-tab" :style="{'display: flex': favorite_form.isEmpty}">
    <div class="favorite-tab__wrapper">
      <template v-if="!favorite_form.isEmpty">
        <template v-if="favorite_form.list.length === 0">
          <div class="favorite-tab__no-items">
            <NotFound title="Список избранных товаров пуст" />
          </div>
        </template>
        <template v-else>
          <FavoriteCard
              v-for="(favoriteItem, index) in favorite_form.list"
              :key="index"
              :favoriteProduct="favoriteItem.product"
          />
        </template>
      </template>
      <div v-else class="favorite-tab__loading">
        <Spinner />
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import FavoriteCard from "@/components/base/favorite-card/FavoriteCard.vue";
import NotFound from "@/components/base/not-found/NotFound.vue";
import Spinner from "@/components/base/spinner/Spinner.vue";

export default {
  name: 'Favorites',
  components: {Spinner, NotFound, FavoriteCard},
  computed: {
    ...mapState('favorite', ['favorite_form']),
  },
};
</script>

<style lang="scss">

.favorite-tab {
  display: block;

  &__wrapper{
    display: flex;
    flex-wrap: wrap;
    padding: 14px;
    gap: 14px;
    width: 100%;
  }

  &__no-items {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 500px);
    width: 100%;
  }

  @media (max-width: 900px) {
    padding: 0;
  }
}
</style>
