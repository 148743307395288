import httpClient from "@/services/http.service";
import errorHandler from "@/utils/plugins/errorHandler";

export async function getCategory() {
    try {
        const { data, status } = await httpClient.get('api/category/index');
        if (status === 200) {
            return { success: true, data: data.data };
        }
    } catch (error) {
        errorHandler(error.message);
        return { success: false, data: null };
    }
}

export async function addCategory(data) {
    try {
        const { data, status } = await httpClient.post('api/category/store', data);
        if (status === 200) {
            return { success: true, data: data.data };
        }
    } catch (error) {
        errorHandler(error.message);
        return { success: false, data: null };
    }
}

export async function editCategory(data, id) {
    try {
        const { data, status } = await httpClient.post(`api/category/update/${id}`, data);
        if (status === 200) {
            return { success: true, data: data.data };
        }
    } catch (error) {
        errorHandler(error.message);
        return { success: false, data: null };
    }
}

export async function deleteCategory(id) {
    try {
        const { data, status } = await httpClient.delete(`api/category/delete/${id}`);
        if (status === 200) {
            return { success: true, data: data.data };
        }
    } catch (error) {
        errorHandler(error.message);
        return { success: false, data: null };
    }
}
