<template>
  <router-link :to="to" class="styled-link">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  name: 'StyledLink',
  props: {
    to: {
      type: String,
      required: true,
    }
  },
};
</script>

<style lang="scss">
.styled-link {
  text-decoration: none;
  color: var(--gray-00);
  cursor: pointer;
}
</style>
