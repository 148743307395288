<template>
  <button
      :class="buttonClass"
      :style="{ width: width }"
      :disabled="disabled"
  >
    <div class="buttonContent">
      <slot v-if="imageFirst" name="icon"></slot>
      <span v-if="title"><Text type="t500w15px" :label="title"/></span>
      <slot v-if="!imageFirst" name="icon"></slot>
    </div>
  </button>
</template>

<script>

import Text from "@/components/base/text/Text.vue";

export default {
  name: 'Button',
  components: {Text},
  props: {
    title: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'normal',
      validator(value) {
        return ['small', 'normal'].includes(value);
      }
    },
    color: {
      type: String,
      default: 'primaryGrey',
      validator(value) {
        return [
          'primaryGreen', 'primaryGrey', 'secondaryGreen', 'primaryWhite',
          'primaryRed', 'secondaryRed', 'transparent', 'transparentGreen'
        ].includes(value);
      }
    },
    icon: {
      type: Object,
      default: null
    },
    imageFirst: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: ''
    }
  },
  computed: {
    buttonClass() {
      return [
        this.disabled ? 'disabledButton' : '',
        this.color,
        'buttonContainer',
        this.size,
        (!this.title && this.size === 'small') ? 'iconOnlySmall' : 'iconOnly'
      ].join(' ');
    }
  }
};
</script>

<style scoped>

.buttonContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 13px 14px;
  background-color: var(--gray-EE);
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  width: fit-content;
  justify-content: center;
  border-width: 0;
}

.buttonContainer * {
  cursor: inherit;
}

.buttonContainer:hover {
  filter: brightness(95%);
}

.buttonContainer:active {
  filter: brightness(95%);
}

.buttonContent {
  display: flex;
  align-items: center;
  gap: 4px;
}

.disabledButton {
  cursor: default !important;
  background-color: var(--gray-8B) !important;
  color: var(--white-100) !important;
}

.small {
  padding: 7px 14px;
}

.iconOnly {
  padding: 14px;
}

.iconOnlySmall {
  padding: 8px;
}

.primaryGreen {
  background-color: var(--green-100);
  color: var(--white-100);
}

.primaryGrey {
  background-color: var(--gray-EE);
  color: var(--gray-00);
}

.primaryWhite {
  background-color: var(--white-100);
  color: var(--gray-00);
}

.secondaryGreen {
  background-color: var(--green-50);
  color: var(--green-100);
}

.primaryRed {
  background-color: transparent;
  color: var(--red-100);
}

.secondaryRed {
  background-color: var(--red-50);
  color: var(--red-100);
}

.transparent {
  background-color: transparent;
  border: 1px solid var(--gray-F5);
}

.transparentGreen {
  background-color: transparent;
  border-width: 0;
  color: var(--green-100);
}
</style>
