<template>
  <div class="count-filter">
    <div class="count-filter__title">Цена</div>
    <div class="count-filter__range-container">
      <div class="count-filter__input-with-label">
        <Text type="t500w13px">От:</Text>
        <div class="count-filter__input-container">
          <input
              class="count-filter__input-legacy"
              :value="firstValue"
              @input="changeFirst"
              disabled
          />
          <img src="@/assets/icons/base/lock.svg" alt="lock" class="count-filter__input-locked-image" />
        </div>
      </div>
      <input
          class="count-filter__range range-f"
          type="range"
          :value="firstValue"
          :min="min"
          :max="max"
          step="1"
          @input="changeFirst"
          :style="{ background: gradientForLine }"
          @mouseup="send"
          @touchend="send"
      />
      <div class="count-filter__input-with-label">
        <Text type="t500w13px">До:</Text>
        <div class="count-filter__input-container">
          <input
              class="count-filter__input-legacy"
              :value="secondValue"
              @input="changeSecond"
              disabled
          />
          <img src="@/assets/icons/base/lock.svg" alt="lock" class="count-filter__input-locked-image" />
        </div>
      </div>
      <input
          class="count-filter__range range-s"
          type="range"
          :value="secondValue"
          :min="min"
          :max="max"
          step="1"
          @input="changeSecond"
          :style="{ background: gradientForLine }"
          @mouseup="send"
          @touchend="send"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CountFilter',
  props: {
    min: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      required: true
    },
    onSelect: {
      type: Function,
      required: true
    },
    initMin: {
      type: Number,
      default: 0
    },
    initMax: {
      type: Number,
      default: 100
    }
  },
  data() {
    return {
      firstValue: this.initMin || this.min,
      secondValue: this.initMax || this.max
    };
  },
  watch: {
    min(newVal) {
      this.firstValue = Math.max(this.firstValue, newVal);
    },
    max(newVal) {
      this.secondValue = Math.min(this.secondValue, newVal);
    }
  },
  computed: {
    gradientForLine() {
      return `linear-gradient(90deg, #DDDDDD ${(this.firstValue / this.max) * 100}%, #CBEFCD ${(this.firstValue / this.max) * 100}%, #CBEFCD ${(this.secondValue / this.max) * 100}%, #DDDDDD ${(this.secondValue / this.max) * 100}%)`;
    }
  },
  methods: {
    changeFirst(event) {
      const newVal = Number(event.target.value);
      if (newVal <= this.secondValue && newVal <= this.max) {
        this.firstValue = newVal;
      }
    },
    changeSecond(event) {
      const newVal = Number(event.target.value);
      if (newVal >= this.firstValue && newVal <= this.max) {
        this.secondValue = newVal;
      }
    },
    send() {
      this.onSelect({ min: this.firstValue, max: this.secondValue });
    }
  }
};
</script>

<style lang="scss" scoped>
.count-filter {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__title {
    font-weight: bold;
  }

  &__range-container {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }

  &__input-with-label {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__input-container {
    display: flex;
    border: 1px solid var(--gray-DD);
    border-radius: 8px;
    width: fit-content;
    background-color: var(--input-background);
    position: relative;
  }

  &__input-legacy {
    border: none;
    background: none;
    padding: 0 12px;
    width: 100%;
    text-align: center;
  }

  &__input-locked-image {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }

  &__range {
    -webkit-appearance: none;
    height: 7px;
    width: 100%;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      background-color: var(--green-100);
      width: 20px;
      height: 20px;
      border-radius: 100%;
      cursor: pointer;
    }
  }

  &__range.range-f, &__range.range-s {
    -webkit-appearance: none;
    height: 7px;
    width: 100%;
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      background-color: var(--green-100);
      width: 20px;
      height: 20px;
      border-radius: 100%;
    }
  }
}
</style>
