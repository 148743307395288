import httpClient from "@/services/http.service";
import errorHandler from "@/utils/plugins/errorHandler";

export const getUrl = async () => {
    const url = `api/robokassa/get-url`;
    try {
        const { data, status } = await httpClient.get(url);
        if (status === 200) {
            return { success: true, data: data.data };
        }
        return { success: false, data: "Unexpected status code" };
    } catch (error) {
        const errorMessage = error.message || "An error occurred";
        errorHandler(errorMessage);
        return { success: false, data: errorMessage };
    }
 };
