<template>
  <div class="login">
    <div class="login__banner"
    :style="{'background': `radial-gradient(50% 50% at 50% 50%, ${banner.colorGradientOne} 30.56%, ${banner.colorGradientTwo} 100%)`}">
      <div class="login__banner-title">
        <Text type="h700w40px" color="white" :label="banner.name"></Text>
        <Text type="h500w24px" color="white" :label="`${banner.price} ₸ / кг`"></Text>
      </div>
      <div class="login__banner-product" v-if="banner.imageUrl">
        <img :src="require(`../${banner.imageUrl}`)" alt="Продукт">
        <div class="shadow-box"></div>
      </div>
      <div class="login__banner-logo">
        <img src="../assets/images/logos/logo-white.svg" alt="Логотип">
      </div>
    </div>
    <div class="login__main">
      <div class="login__main-to-home">
        <Button title="На главную страницу" color="primaryWhite" size="small" @click="this.$router.push('/')">
          <template #icon>
            <BaseIcon width="16" height="16" name="leftArrow"/>
          </template>
        </Button>
      </div>
      <div class="login__main-actions">
        <LoginForm/>
      </div>
      <div class="login__main-footer">
        <div class="login__main-footer-links">
          <a class="login__main-footer-link" href="#">Публичная оферта</a>
          <a class="login__main-footer-link" href="#">Пользовательское соглашение</a>
          <a class="login__main-footer-link" href="#">Политика конфиденциальности</a>
        </div>
        <div class="login__main-footer-phone">
          <Text label="+7 (700) 777 77 77" type="t500w15px" color="green" link/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import banners from '../assets/data/login/banners.json';
import Text from "@/components/base/text/Text.vue";
import Button from "@/components/base/button/Button.vue";
import BaseIcon from "@/components/base/base-icon/BaseIcon.vue";
import LoginForm from "@/components/login/LoginForm.vue";
export default {
  name: "LoginView",
  components: {LoginForm, BaseIcon, Text, Button},
  data() {
    return {
      activeTab: 0,
      banner: banners[Math.floor(Math.random() * banners.length)]
    };
  },
  methods: {
  }
};
</script>

<style scoped lang="scss">
.login {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  &__banner {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    padding: 56px;
    min-width: 576px;
  }

  &__banner-title {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__banner-product{
    display: flex;
    justify-content: center;
    position: relative;
  }

  &__banner-product::after {
    content: "";
    position: absolute;
    top: calc(100% + 30px);
    left: 50%;
    transform: translateX(-50%);
    width: 400px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    filter: blur(10px);
  }

  &__main{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 14px;
  }

  &__main-actions{
    display: flex;
    justify-content: center;
  }

  &__main-footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__main-footer-links{
    display: flex;
    flex-direction: row;
    gap: 28px;
  }

  &__main-footer-phone{
    text-align: center;
  }

  &__main-footer-link {
    font-size: 15px;
    font-weight: 500;
    color: var(--gray-8B);
    text-decoration: none;
    cursor: pointer;
    text-align: center;

    &:hover {
      text-decoration: underline;
    }
  }

  &__main-footer-phone{
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    &__main-footer {
      flex-direction: column;
    }

    &__main-footer-links{
      flex-direction: column;
      gap: 10px;
    }

    &__main {
      padding: 14px;
    }

    &__main-footer-phone{
      margin-top: 10px;
    }
  }

  @media screen and (min-width: 768px) {
    &__main {
      padding: 28px 56px;
    }

    &__main-footer-phone{
      display: flex;
      justify-content: center;
    }
  }

  @media screen and (min-width: 1200px) {

    &__banner {
      display: flex;
    }

    &__main {
      padding: 56px 112px;
    }

    &__main-footer-phone{
      display: flex;
    }
  }

  @media screen and (min-width: 1200px) and (max-width: 1400px){
    &__main-footer {
      flex-direction: column;
    }

    &__main-footer-phone{
      margin-top: 10px;
    }
  }
}
</style>
