import httpClient from "@/services/http.service";
import errorHandler from "@/utils/plugins/errorHandler";

export const getOrders = async () => {
    try {
        const { data, status } = await httpClient.get('api/order/index');
        if (status === 200) {
            return { success: true, data: data.data };
        }
    } catch (error) {
        errorHandler(error.message);
        return { success: false, data: error.message };
    }
};

export const getOrder = async (id) => {
    try {
        const { data, status } = await httpClient.get(`api/order/show/${id}`);
        if (status === 200) {
            return { success: true, data: data.data };
        }
    } catch (error) {
        errorHandler(error.message);
        return { success: false, data: error.message };
    }
};

export const addOrder = async (data) => {
    try {
        const { data: responseData, status } = await httpClient.post('api/order/store', data);
        if (status === 200) {
            return { success: true, data: responseData.data };
        }
    } catch (error) {
        errorHandler(error.message);
        return { success: false, data: error.message };
    }
};

export const editOrder = async (data, id) => {
    try {
        const { data: responseData, status } = await httpClient.post(`api/order/update/${id}`, data);
        if (status === 200) {
            return { success: true, data: responseData.data };
        }
    } catch (error) {
        errorHandler(error.message);
        return { success: false, data: error.message };
    }
};

export const getOrderStatus = async () => {
    try {
        const { data, status } = await httpClient.get('api/order/status');
        if (status === 200) {
            return { success: true, data: data.data };
        }
    } catch (error) {
        errorHandler(error.message);
        return { success: false, data: error.message };
    }
};

export const cancelOrder = async (id) => {
    try {
        const { data, status } = await httpClient.get(`api/order/cancel/${id}`);
        if (status === 200) {
            return { success: true, data: data.data };
        }
    } catch (error) {
        errorHandler(error.message);
        return { success: false, data: error.message };
    }
};

export const getLastOrder = async () => {
    try {
        const { data, status } = await httpClient.get('api/order/show/1?isLast=1');
        if (status === 200) {
            return { success: true, data: data.data };
        }
    } catch (error) {
        errorHandler(error.message);
        return { success: false, data: error.message };
    }
};
