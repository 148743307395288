<template>
  <form class="code-input__container">
    <div class="code-input__list" id="form">
      <input
          v-for="(item, index) in inputsAr"
          :ref="el => setItemRef(el, index)"
          :name="`code-${index}`"
          :key="index"
          @input="codeChangeHandler($event, index)"
          @keydown="handleKeyDown($event, index)"
          maxlength="1"
          class="code-input__input"
          :disabled="isDisabled"
          placeholder="Х"
          type="tel"
          autocomplete="off"
      />
    </div>
    <div v-if="error" class="code-input__error">
      <Text type="t500w13px" color="red" :label="error" />
    </div>
    <Spinner v-if="isDisabled" />
  </form>
</template>

<script>
import Text from "@/components/base/text/Text.vue";
import Spinner from "@/components/base/spinner/Spinner.vue";
import authService from "@/services/auth.service";
import {mapActions} from "vuex";

export default {
  name: 'CodeInput',
  components: {
    Spinner,
    Text,
  },
  props: {
    phone: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      inputsAr: ['', '', '', '', '', ''],
      code: [],
      error: '',
      isDisabled: false,
      itemsRef: new Array(6),
    }
  },
  mounted() {
    this.setFocusOnFirstInput();
  },
  methods: {
    ...mapActions(['updateLoginStatus']),
    setItemRef(el, index) {
      if (el) {
        this.itemsRef[index] = el;
      }
    },
    setFocusOnFirstInput() {
      this.$nextTick(() => {
        if (this.itemsRef[0]) {
          this.itemsRef[0].focus();
        }
      });
    },
    async codeChangeHandler(event, index) {
      const element = event.target;
      const nextSibling = element.nextElementSibling;
      const prevSibling = element.previousElementSibling;

      if (element.value === '' && prevSibling) {
        element.blur();
        prevSibling.focus();
      }

      if (!Number(element.value)) {
        element.value = element.value.replace(/[^\d,]/g, '');
      } else {
        if (nextSibling) {
          nextSibling.focus();
        } else {
          element.blur();
        }

        this.code[index] = element.value;

        if (!nextSibling) {
          this.isDisabled = true;
          const response = await authService.login({phone: `7${this.phone}`, code: this.code.join('')});

          if (response.success) {
            await this.updateLoginStatus();
            this.$router.push('/');
          } else {
            this.error = 'Введён неверный код!';
            this.isDisabled = false;
            this.itemsRef.forEach((ref, idx) => {
              ref.value = '';
            });
            this.code = [];
          }
        }
      }
    },
    handleKeyDown(event, index) {
      if (event.key === 'ArrowRight' && this.itemsRef[index + 1]) {
        setTimeout(() => {
          this.itemsRef[index + 1].focus();
          this.itemsRef[index + 1].setSelectionRange(1, 1);
        }, 0);
      } else if (event.key === 'ArrowLeft' && this.itemsRef[index - 1]) {
        setTimeout(() => {
          this.itemsRef[index - 1].focus();
          this.itemsRef[index - 1].setSelectionRange(1, 1);
        }, 0);
      } else if (event.key === 'ArrowUp' && this.itemsRef[index - 1]) {
        setTimeout(() => {
          this.itemsRef[index - 1].focus();
          this.itemsRef[index - 1].setSelectionRange(1, 1);
        }, 0);
      } else if (event.key === 'ArrowDown' && this.itemsRef[index + 1]) {
        setTimeout(() => {
          this.itemsRef[index + 1].focus();
          this.itemsRef[index + 1].setSelectionRange(1, 1);
        }, 0);
      } else if (event.key === 'Backspace') {
        if (this.code[index]) {
          this.code[index] = '';
        } else if (index > 0) {
          setTimeout(() => {
            this.itemsRef[index - 1].focus();
            this.itemsRef[index - 1].setSelectionRange(1, 1);
          }, 0);
          this.code[index - 1] = '';
          this.itemsRef[index - 1].value = '';
          event.preventDefault();
        }
      }
    }
  },
  beforeDestroy() {
    this.itemsRef = [];
  }
};
</script>

<style lang="scss">
.code-input {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__list {
    display: flex;
    gap: 8px;
  }

  &__input {
    padding: 10px;
    width: 38px;
    height: 44px;
    font-size: 14px;
    border: 1px solid var(--gray-DD);
    border-radius: 8px;
    text-align: center;
    color: black;
    background-color: white;
    caret-color: black;
    outline: none;

    &:focus {
      border-color: var(--blue-50);
      box-shadow: 0 0 5px var(--blue-50);
    }
  }

  &__error {
    display: flex;
    align-self: center;
  }
}
</style>
