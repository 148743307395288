<script>
import Slider from "@/components/base/slider/Slider.vue";
import {getSliders} from "@/http/slider/slider";
import CategorySlider from "@/components/base/category-slider/CategorySlider.vue";
import Category from "@/components/base/category/Category.vue";
import CatalogCard from "@/components/base/catalog-card/CatalogCard.vue";
import {mapActions, mapState} from "vuex";
import Spinner from "@/components/base/spinner/Spinner.vue";
import ShopCard from "@/components/base/shop-card/ShopCard.vue";
import {getDiscountProducts} from "@/http/discount-product/discount-product";
import {getTopProducts} from "@/http/top-product/top-product";
import ShopCardSkeleton from "@/components/base/skeleton/shop-card-skeleton/ShopCardSkeleton.vue";
import CatalogCardSkeleton from "@/components/base/skeleton/catalog-card-skeleton/CatalogCardSkeleton.vue";
export default {
  name: "MainView",
  components: {CatalogCardSkeleton, ShopCardSkeleton, ShopCard, Spinner, CatalogCard, Category, CategorySlider, Slider},
  data(){
    return{
      sliders: [],
      topProducts: [],
      discountProducts: [],
      loading: true,
    }
  },
  computed:{
    ...mapState('category', ['categories']),
  },
  methods: {
    ...mapActions('category', ['getCategories']),
    async getSliders(){
      let res = await getSliders();
      if (res.success){
        this.sliders = res.data
      }
    },
    async getTopProducts(){
      let res = await getTopProducts();
      if (res.success){
        this.topProducts = res.data
      }
    },
    async getDiscountProducts(){
      let res = await getDiscountProducts();
      if (res.success){
        this.discountProducts = res.data
      }
    },
  },
  async created() {
    await this.getSliders()
    await this.getCategories()
    await this.getTopProducts()
    await this.getDiscountProducts()
    this.loading = false
  }
}
</script>

<template>
  <div class="main">
    <Slider :slides="sliders"/>
    <Category title="Каталоги">
      <CategorySlider v-if="categories.length">
        <CatalogCard v-for="(category, index) in categories" :image="category.desktop_image_url"
                     :key="index" :id="category.id" :title="category.name_ru"/>
      </CategorySlider>
      <CategorySlider v-else items-gap="14">
        <CatalogCardSkeleton v-for="i in 8" :key="i"/>
      </CategorySlider>
    </Category>
    <Category title="Топ товаров со скидкой">
      <CategorySlider v-if="topProducts.products?.length" items-gap="14">
        <ShopCard v-for="(product, index) in topProducts.products" :key="index" :product="product"/>
      </CategorySlider>
      <CategorySlider v-else items-gap="14">
        <ShopCardSkeleton v-for="i in 6" :key="i"/>
      </CategorySlider>
    </Category>
    <Category title="Топ товаров со скидкой">
      <CategorySlider v-if="discountProducts.products?.length" items-gap="14">
        <ShopCard v-for="(product, index) in discountProducts.products" :key="index" :product="product"/>
      </CategorySlider>
      <CategorySlider v-else items-gap="14">
        <ShopCardSkeleton v-for="i in 6" :key="i"/>
      </CategorySlider>
    </Category>
  </div>
</template>

<style scoped lang="scss">
.main{
  padding: 14px 0 0;
  gap: 28px;
}
</style>