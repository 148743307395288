<template>
  <div class="input-container" :style="{ minWidth: minWidth || '293px' }">
    <div v-if="label" class="input-container__label">
      <Text type="t600w13px" :label="label"/>
    </div>
    <div
        :class="[
        'input-container__input',
        { 'input-container__input--locked': locked },
        { 'input-container__input--succeed': succeed },
        { 'input-container__input--error': errorMessage },
        { 'input-container__input--has-text': value !== '' }
      ]"
    >
      <input
          :class="[
          'input-container__legacy-input',
          { 'input-container__legacy-input--locked': locked }
        ]"
          :placeholder="placeholder"
          v-model="value"
          :disabled="disabled"
          :type="type"
          :required="required"
          :max="max"
          :min="min"
          @input="handleChange"
      />
      <img src="@/assets/icons/base/lock.svg" alt="lock" v-if="locked" class="input-container__lock-image" />
    </div>
    <div v-if="errorMessage" class="input-container__error-message">
      <Text type="t500w12px" :label="errorMessage"/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Input',
  props: {
    label: String,
    disabled: Boolean,
    locked: Boolean,
    succeed: Boolean,
    errorMessage: String,
    placeholder: String,
    initialValue: String,
    type: String,
    required: Boolean,
    onChange: Function,
    minWidth: String,
    max: Number,
    min: Number,
  },
  data() {
    return {
      value: this.initialValue || '',
    };
  },
  methods: {
    handleChange(event) {
      this.value = event.target.value;
      if (this.onChange) {
        this.onChange(this.value);
      }
    },
  },
};
</script>

<style lang="scss">

.input-container {
  display: flex;
  flex-direction: column;

  &__label {
    margin-bottom: 8px;
  }

  &__input {
    position: relative;
    border: 1px solid var(--gray-DD);
    border-radius: 8px;

    &--locked {
      background-color: var(--input-background);
    }

    &--succeed {
      border-color: var(--green-100);
    }

    &--error {
      border-color: var(--red-100);
    }

    &--has-text {
      /* Additional styles if needed */
    }
  }

  &__legacy-input {
    border: 0;
    background-color: transparent;
    width: calc(100% - 26px);
    padding: 13px;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings: "slnt" 0;
    font-weight: 500;
    font-size: 15px;
    color: var(--gray-4F);

    &::placeholder {
      color: var(--gray-DC);
    }

    &:focus {
      outline: none;
      border-color: var(--blue-50);

      &::placeholder {
        color: transparent;
      }
    }

    &--locked {
      width: calc(100% - 50px);
    }
  }

  &__lock-image {
    position: absolute;
    right: 13px;
    top: 13px;
  }

  &__error-message {
    margin: 8px 0 0 13px;
    color: var(--red-100);
  }
}
</style>
