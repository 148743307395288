import httpClient from "@/services/http.service";
import errorHandler from "@/utils/plugins/errorHandler";

export const getCountries = async (perPage, page) => {
    try {
        const url = perPage && page
            ? `api/country/index?perPage=${perPage}&page=${page}`
            : 'api/country/index';
        const { data, status } = await httpClient.get(url);
        if (status === 200) {
            return { success: true, data: data.data };
        }
    } catch (error) {
        errorHandler(error.message);
        return { success: false, data: error.message };
    }
};

export const getFilteredCountries = async (category_id, subcategory_id) => {
    try {
        const { data, status } = await httpClient.get('api/country/index', {
            params: {
                category_id: Number(category_id),
                subcategory_id: [subcategory_id],
            }
        });
        if (status === 200) {
            return { success: true, data: data.data };
        }
    } catch (error) {
        errorHandler(error.message);
        return { success: false, data: error.message };
    }
};

export const addCountry = async (data) => {
    try {
        const { data: responseData, status } = await httpClient.post('api/country/store', data);
        if (status === 200) {
            return { success: true, data: responseData.data };
        }
    } catch (error) {
        errorHandler(error.message);
        return { success: false, data: error.message };
    }
};

export const editCountry = async (data, id) => {
    try {
        const { data: responseData, status } = await httpClient.post(`api/country/update/${id}`, data);
        if (status === 200) {
            return { success: true, data: responseData.data };
        }
    } catch (error) {
        errorHandler(error.message);
        return { success: false, data: error.message };
    }
};

export const deleteCountry = async (id) => {
    try {
        const { data: responseData, status } = await httpClient.delete(`api/country/delete/${id}`);
        if (status === 200) {
            return { success: true, data: responseData.data };
        }
    } catch (error) {
        errorHandler(error.message);
        return { success: false, data: error.message };
    }
};
