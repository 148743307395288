<template>
  <div class="category-container">
    <div class="category-header">
      <Text type="h700w24px" :label="title" />
      <slot name="rightBlock"></slot>
    </div>
    <slot></slot>
  </div>
</template>

<script>

import Text from "@/components/base/text/Text.vue";

export default {
  name: 'Category',
  components: {Text},
  props: {
    title: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped>
.category-container {
}

.category-header {
  display: flex;
  justify-content: space-between;
  margin: 28px 0 14px 0;
  align-items: center;
  user-select: none;
}
</style>