<script>
import {mediaQuery} from "@/utils/helpers/mediaQuery";
import {getCookie} from "@/utils/helpers/cookies.helpers";
import Button from "@/components/base/button/Button.vue";
import BurgerMenu from "@/components/base/burger-menu/BurgerMenu.vue";
import Search from "@/components/base/search/Search.vue";
import {mapState} from "vuex";

export default {
  name: 'HeaderNavigatorBar',
  components: {Search, BurgerMenu, Button},
  data() {
    return {
      isMobile: mediaQuery('(max-width: 768px)'),
      favoriteList: [],
      cartList: [],
    }
  },
  computed:{
    ...mapState(['isLoggedIn']),
    ...mapState('favorite', ['favorite_form']),
  },
  methods: {
    getCookie,
    onSearchChange(val) {
      console.log('onSearchChange', val)
    },
    onSearch(val) {
      console.log('onSearch', val)
    },
    navigateToAdmin() {
      this.$router.push('/admin');
    },
    navigateToProfile() {
      this.$router.push({ name: 'profile', params: { tab: 1 } });
    },
    goToLogin() {
      this.$router.push('/login');
    },
    goToFavorites() {
      this.$router.push('/profile/favorite')
    },
    goToCart() {

    },
  },
  mounted() {
  },
  beforeUnmount() {
  },
};
</script>

<template>
  <div class="header">
    <div class="header__wrapper">
      <div class="header__row">
        <div v-if="!isMobile" class="header__logo-menu-search">
          <router-link to="/" class="header__logo-link">
            <img src="../../assets/images/logos/logo.svg" alt="?">
          </router-link>
          <BurgerMenu />
          <Search
              v-if="!isMobile"
              placeholder="Поиск товара по названию"
              :width="480"
              :onChange="onSearchChange"
              :onSearch="onSearch"
              :maxLength="44"
          />
        </div>
        <BurgerMenu v-if="isMobile"/>
        <router-link v-if="isMobile" to="/" class="header__logo-link">
          <img src="../../assets/images/logos/logo.svg" alt="?">
        </router-link>
        <div class="header__local">
          <template v-if="!isMobile">
            <Button v-if="getCookie('ROLES') === 'admin'" title="Admin" @click="navigateToAdmin" />
            <template v-if="isLoggedIn">
              <Button title="Профиль" @click="navigateToProfile" isImageFirst>
                <template #icon>
                  <img src="../../assets/icons/base/profile.svg" alt="">
                </template>
              </Button>
            </template>
            <template v-else>
              <Button title="Войти" @click="goToLogin"/>
            </template>
            <Button
                :title="`${favorite_form.list.length}`"
                color="transparent"
                @click="goToFavorites"
                isImageFirst
            >
              <template #icon>
                <img src="../../assets/icons/base/heart.svg" alt="">
              </template>
            </Button>
          </template>
          <Button
              :title="`${cartList.length}`"
              color="primaryGreen"
              @click="goToCart"
              isImageFirst
          >
            <template #icon>
              <img src="../../assets/icons/base/cart.svg" alt="">
            </template>
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  padding: 18px 0;
  border-bottom: 1px solid var(--gray-F5);
  z-index: 2;
  position: relative;
  background-color: var(--white-100);

  &__wrapper {
    max-width: 1304px;
    width: 100%;
  }

  &__row {
    display: flex;
    justify-content: space-between;
  }

  &__logo-menu-search {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__logo-burger {
    display: flex;
    align-items: center;
  }

  &__local {
    display: flex;
    gap: 8px;
  }

  @media (max-width: 1305px) {
    &__lang {
      padding-right: 10px;
    }

    &__row {
      padding: 0 10px;
    }
  }

  @media (max-width: 768px) {
    &__row {
      justify-content: space-between;
      align-items: center;
    }

    &__logo-menu-search {
      justify-content: space-between;
    }
  }
}
</style>