import httpClient from "@/services/http.service";
import errorHandler from "@/utils/plugins/errorHandler";

export async function getFavorite() {
    try {
        const { data, status } = await httpClient.get('api/favorite-product/index');
        if (status === 200) {
            return { success: true, data: data.data };
        }
    } catch (error) {
        errorHandler(error.message);
        return { success: false, data: null };
    }
}

export async function createFavorite(product_id) {
    try {
        const { data, status } = await httpClient.post('api/favorite-product/store', { product_id });
        if (status === 200) {
            return { success: true, data: data.data };
        }
    } catch (error) {
        errorHandler(error.message);
        return { success: false, data: null };
    }
}

export async function deleteFavorite(id) {
    try {
        const { data, status } = await httpClient.delete(`api/favorite-product/delete/${id}`);
        if (status === 200) {
            return { success: true, data: data.data };
        }
    } catch (error) {
        errorHandler(error.message);
        return { success: false, data: null };
    }
}
