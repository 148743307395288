import { ref, watch, onMounted, onUnmounted } from 'vue';

export function mediaQuery(query) {
    const matches = ref(false);

    const updateMatches = () => {
        matches.value = window.matchMedia(query).matches;
    };

    let mediaQueryList;

    onMounted(() => {
        mediaQueryList = window.matchMedia(query);
        updateMatches();
        mediaQueryList.addListener(updateMatches);
    });

    onUnmounted(() => {
        if (mediaQueryList) {
            mediaQueryList.removeListener(updateMatches);
        }
    });

    watch(() => query, () => {
        if (mediaQueryList) {
            mediaQueryList.removeListener(updateMatches);
        }
        mediaQueryList = window.matchMedia(query);
        updateMatches();
        mediaQueryList.addListener(updateMatches);
    });

    return matches;
}
