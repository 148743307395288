<template>
  <div class="skeleton-block"></div>
</template>

<script>
export default {
  name: 'SliderSkeleton',
};
</script>

<style lang="scss">
.skeleton-block {
  width: 100%;
  height: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  animation: shimmer 2s infinite;

  @keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
  background: linear-gradient(
          to right,
          #e0e0e0 0%,
          #f0f0f0 50%,
          #e0e0e0 100%
  );
  background-size: 200% 100%;
}
</style>
