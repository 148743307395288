<template>
  <div>
    <div
        v-if="!isDropdown"
        @mouseenter="isHovered = true"
        @mouseleave="isHovered = false"
        :class="[isColumn ? 'burger-item__container--column' : 'burger-item__container']"
    >
      <img v-if="icon" :src="`${apiURL}${icon}`" alt="Category" width="16" />
      <div v-else class="burger-item__image-not-found" />
      <Text :color="isHovered || selected ? 'green' : 'black'" :label="title" type="t500w13px" link />
    </div>
    <div v-else>
      <div class="burger-item__container burger-item__dropdown" @click="isOpen = !isOpen">
        <div class="burger-item__dropdown-category">
          <img :src="`${apiURL}${icon}`" alt="Category" width="16" />
          <Text :color="isOpen ? 'green' : 'black'" :label="title" type="t600w17px" />
        </div>
        <img src="../../../assets/icons/base/down-arrow.svg" alt="?"
             :style="{ transition: 'all .3s', transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }" />
      </div>
      <div v-if="isOpen" class="burger-item__dropdown-subcategories">
        <div
            v-for="item in filteredDropdownList"
            :key="item.id"
            class="burger-item__dropdown-subcategories-item"
            @click="dropdownClick(item.category_id, item.name_ru, item.id)"
        >
          <Text type="t600w17px" color="gray" :label="item.name_ru" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Text from "@/components/base/text/Text.vue";

export default {
  name: "BurgerItem",
  components: {Text},
  props: {
    id: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    isColumn: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    isDropdown: {
      type: Boolean,
      default: false
    },
    dropdownList: {
      type: Array,
      default: () => []
    },
    dropdownClick: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      isHovered: false,
      isOpen: false,
      apiURL: process.env.VUE_APP_API_URL
    };
  },
  computed: {
    filteredDropdownList() {
      return this.dropdownList.filter(subCategory => subCategory?.category_id === this.id);
    }
  }
};
</script>

<style lang="scss" scoped>

.burger-item {
  &__container {
    display: flex;
    padding: 8px 14px;
    gap: 8px;
    cursor: pointer;

    &--column {
      flex-direction: column;
      min-height: 122px;
      gap: 4px;
      max-width: 116px;
      max-height: fit-content;
    }
  }

  &__image-not-found {
    width: 116px;
    height: 88px;
    border-radius: 8px;
    background-color: var(--gray-F5);
  }

  &__dropdown {
    justify-content: space-between;

    &-category {
      display: flex;
      gap: 8px;
    }

    &-subcategories {
      padding: 11px 38px;

      &-item {
        padding: 10px 0;

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}
</style>
