<template>
  <router-view/>
  <Notification />
</template>

<script>
import {mapActions} from "vuex";

export default {
  methods: {
    ...mapActions(['updateLoginStatus']),
  },
  created() {
    this.updateLoginStatus();
  },
}
</script>

<style lang="scss">
</style>
