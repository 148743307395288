<script>
import HeaderComponent from "@/components/header/HeaderComponent.vue";
import HeaderNavigatorBar from "@/components/header/HeaderNavigatorBar.vue";

export default {
  name: "Header",
  components: {HeaderNavigatorBar, HeaderComponent}
}
</script>

<template>
  <header class="header">
    <HeaderComponent/>
    <HeaderNavigatorBar/>
  </header>
</template>

<style scoped lang="scss">
.header{
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 9;
  margin-bottom: 14px;
}
</style>