<template>
  <div class="catalog-page">
    <CatalogPath
        v-if="location === null"
        :firstCategory="'Каталог'"
        :isFirstBlack="true"
    />
    <CatalogPath
        v-else-if="location.subCategoryName"
        :firstCategory="'Каталог'"
        :secondCategory="location.categoryName"
        :thirdCategory="location.subCategoryName"
        :isFirstBlack="true"
    />
    <CatalogPath
        v-else
        :firstCategory="'Каталог'"
        :secondCategory="'Поиск'"
        :isFirstBlack="true"
    />
    <div v-if="selectedFilters.length" class="catalog-page__selected-filters">
      <div
          v-for="filter in selectedFilters"
          :key="filter.filterId"
          class="catalog-page__filter-item"
      >
        <Text type="t500w12px" :label="filter.filterName" link />
      </div>
    </div>
    <div class="catalog-page__content">
      <div v-if="!isMobile" class="catalog-page__side-menu">
        <CountFilter
            :min="0"
            :max="catalog.max_price"
            :on-select="changePriceFilter"/>
        <FilterWithSearch
            header="Бренды"
            :list="brands"
            :selectedFilters="selectedFilters"
            :set-selected-filters="toggleFilters"
        />
        <FilterWithSearch
            header="Страны"
            :list="countries"
            :selectedFilters="selectedFilters"
            :set-selected-filters="toggleFilters"
        />
      </div>
      <div v-if="isOpenMobileFilters" class="catalog-page__side-menu catalog-page__side-menu--mobile">
        <CountFilter
            :min="0"
            :max="catalog.max_price"
            :initMin="priceFilter.min"
            :initMax="priceFilter.max"
            :on-select="changePriceFilter"
        />
        <FilterWithSearch
            header="Бренды"
            :list="brands"
            :selectedFilters="selectedFilters"
            :set-selected-filters="toggleFilters"
        />
        <FilterWithSearch
            header="Страны"
            :list="countries"
            :selectedFilters="selectedFilters"
            :set-selected-filters="toggleFilters"
            />
        <Button title="Скрыть фильтры" width="100%" color="primaryGreen" @click="setIsOpenMobileFilters(false)" />
      </div>
      <div class="catalog-page__main-block">
        <CatalogHeader
            :title="headerTitle"
            :count="catalog.products?.length || 0"
        />
        <div v-if="isMobile" class="catalog-page__mobile-header">
          <Button title="Фильтры" width="35%" @click="setIsOpenMobileFilters(true)" >
            <template #icon>
              <img src="@/assets/icons/base/filter.svg" alt="filter">
            </template>
          </Button>
          <Dropdown :list="dropList" width="55%" />
        </div>
        <div class="catalog-page__products-list">
          <div v-if="loading" class="catalog-page__loader">
            <Spinner />
          </div>
          <template v-else>
            <ShopCard v-for="product in catalog.products" :key="product.id" :product="product" />
            <div v-if="!catalog.products?.length" class="catalog-page__empty">
              <Text type="h700w24px" label="Ничего не найдено" />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFilteredBrands } from '@/http/brand/brand';
import { getFilteredCountries } from '@/http/country/country';
import { getFilteredProducts } from '@/http/product/product';
import CatalogPath from "@/components/base/catalog-path/CatalogPath.vue";
import CatalogHeader from "@/components/base/catalog-header/CatalogHeader.vue";
import Spinner from "@/components/base/spinner/Spinner.vue";
import ShopCard from "@/components/base/shop-card/ShopCard.vue";
import Dropdown from "@/components/base/dropdown/Dropdown.vue";
import CountFilter from "@/components/base/count-filter/CountFilter.vue";
import FilterWithSearch from "@/components/base/filter-with-search/FilterWithSearch.vue";

export default {
  components: {
    Dropdown,
    ShopCard,
    Spinner,
    CatalogPath,
    CatalogHeader,
    CountFilter,
    FilterWithSearch,
  },
  data() {
    return {
      brands: [],
      countries: [],
      selectedFilters: [],
      catalog: {
        max_price: 999999999999,
        min_price: 0,
        products: [],
      },
      priceFilter: {
        min: 0,
        max: 999999999999,
      },
      loading: false,
      isOpenMobileFilters: false,
      isMobile: window.innerWidth <= 750,
      dropList: [
        { id: 0, title: 'Цена по возрастанию' },
        { id: 1, title: 'Цена по убыванию' },
      ],
    };
  },
  computed: {
    firstCategory() {
      const location = this.$route.params;
      if (!location) return 'Каталог';
      if (location.categoryName) return location.categoryName;
      return 'Поиск';
    },
    headerTitle() {
      const location = this.$route.state;
      if (!location) return 'Каталог';
      if (location.categoryName) return location.categoryName;
      if (location.search.length < 30) {
        return `Результаты поиска: ${location.search}`;
      }
      return `Результаты поиска: ${location.search}`;
    }
  },
  methods: {
    async fetchFilteredProducts() {
      try {
        this.loading = true;
        const location = this.$route.state;
        const result = await getFilteredProducts(
            location.search || '',
            location.categoryId,
            location.subCategoryId,
            this.selectedFilters,
            this.priceFilter.min,
            this.priceFilter.max
        );
        this.catalog = result.data;
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        this.loading = false;
      }
    },
    async fetchBrands() {
      try {
        this.loading = true;
        const location = this.$route.state;
        const result = await getFilteredBrands(
            location.categoryId || 1,
            location.subCategoryId
        );
        this.brands = result.data;
      } catch (error) {
        console.error('Error fetching brands:', error);
      } finally {
        this.loading = false;
      }
    },
    async fetchCountries() {
      try {
        this.loading = true;
        const location = this.$route.state;
        const result = await getFilteredCountries(
            location.categoryId || 1,
            location.subCategoryId
        );
        this.countries = result.data;
      } catch (error) {
        console.error('Error fetching countries:', error);
      } finally {
        this.loading = false;
      }
    },
    setSelectedFilters(filterField) {
      return (filterId, filterName) => {
        const existingFilter = this.selectedFilters.find(
            filter => filter.filterName === filterName
        );
        if (existingFilter) {
          this.selectedFilters = this.selectedFilters.filter(
              filter => filter.filterName !== filterName
          );
        } else {
          this.selectedFilters = [
            ...this.selectedFilters,
            { filterId, filterName, filterField }
          ];
        }
      };
    },
    changePriceFilter(values) {
      if (this.priceFilter.min !== values.min || this.priceFilter.max !== values.max) {
        this.priceFilter = values;
      }
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 750;
    }
  },
  mounted() {
    this.fetchBrands();
    this.fetchCountries();
    this.fetchFilteredProducts();
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>

<style lang="scss">
.catalog-page {
  display: flex;
  flex-direction: column;
  gap: 14px;

  &__content {
    display: flex;
    gap: 28px;
  }

  &__side-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 198px;
    gap: 20px;

    &--mobile {
      position: fixed;
      top: 0;
      left: 0;
      background-color: var(--white-100);
      width: calc(100% - 20px);
      height: calc(100% - 20px);
      z-index: 5;
      padding: 10px;
      justify-content: space-between;
    }
  }

  &__main-block {
    display: flex;
    flex-direction: column;
    gap: 28px;
    width: 100%;
  }

  &__selected-filters {
    display: flex;
    gap: 2px;
    flex-wrap: wrap;
  }

  &__filter-item {
    padding: 4.5px 9px;
    background-color: var(--gray-EE);
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      background-color: var(--gray-DD);
    }
  }

  &__products-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 14px;
  }

  &__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40vh;
  }

  &__mobile-header {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 750px) {
  .catalog-page__side-menu {
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--white-100);
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    z-index: 5;
    padding: 10px;
    justify-content: space-between;
  }
}
</style>
