<template>
  <div v-if="!isLoading" class="order-page__container">
    <div class="order-page__header">
      <div class="order-page__back" @click="navigateToProfile">
        <img src="@/assets/icons/base/left-arrow.svg" alt="<"/>
        <Text type="t500w15px" :label="'Все заказы'" link />
      </div>
      <div class="order-page__header-text">
        <Text type="h700w24px" :label="`Заказ №${order.id}`" />
        <Badge
            :backgroundColor="getBackgroundStatus(order.order_status?.name || '')"
            :title="order.order_status?.name"
            :color="order.order_status?.name === 'Ожидание' ? 'black' : 'white'"
        />
      </div>
    </div>
    <div class="order-page__info">
      <div class="order-page__details">
        <div class="order-page__details-table">
          <div class='order-page__details-row' v-for="(row, index) in arDetails" :key="index">
            <Text type='t500w13px' :label="row.label" color='gray' />
            <Text type='t500w13px' :label="row.title" :color="row.color" />
          </div>
        </div>
        <div v-if="order.order_status?.name === 'Не оплачен'" class="order-page__controls">
          <StyledLink v-if="order.payment_type_id === 2" :to="url?.robokassa_url">
            <Button title="Оплатить заказ" color="primaryGreen" :width="isMobile ? '100%' : ''" />
          </StyledLink>
          <Button title="Отменить заказ" color="secondaryRed" :width="isMobile ? '100%' : ''" @click="cancelOrderFunction(order.id)" />
        </div>
        <div class="order-page__product-container">
          <Text type="h700w20px" label="Список товаров" />
          <div class="order-page__product-list">
            <OrderCard
                v-for="product in order.products"
                :key="product.id"
                :product="product"
            />
          </div>
        </div>
      </div>
      <TotalTable
          v-if="!isMobile"
          :price="order.products_price"
          :currency="currency.KZ"
          :totalPrice="order.total_price"
          :deliveryCost="order.delivery_price"
      />
    </div>
    <TotalTable
        v-if="isMobile"
        :price="order.products_price"
        :currency="currency.KZ"
        :totalPrice="order.total_price"
        :deliveryCost="order.delivery_price"
    />
  </div>
  <div v-else class="order-page__loading">
    <Spinner />
  </div>
</template>

<script>
import {getBackgroundStatus, getColorStatus, getFormattedDate} from "@/utils/functions/functions";
import Badge from "@/components/base/badge/Badge.vue";
import Spinner from "@/components/base/spinner/Spinner.vue";
import {getUrl} from "@/http/robokassa/robokassa";
import currency from "@/assets/data/currency/currency";
import {cancelOrder, getOrder} from "@/http/order/order";
import {mediaQuery} from "@/utils/helpers/mediaQuery";
import TotalTable from "@/components/base/total-table/TotalTable.vue";
import OrderCard from "@/components/base/order-card/OrderCard.vue";

export default {
  name: 'OrderView',
  components: {
    Spinner,
    Badge,
    TotalTable,
    OrderCard,
  },
  data() {
    return {
      order: {},
      isLoading: true,
      url: null,
      isMobile: mediaQuery({ query: '(max-width: 900px)' }),
    };
  },
  computed: {
    currency() {
      return currency
    },
    arDetails() {
      return [
        { label: 'Дата оформления', title: getFormattedDate(this.order.created_at), color: 'black' },
        { label: 'Дата и время доставки', title: `${getFormattedDate(this.order.delivery_date)}, ${this.order.delivery_interval.name}`, color: 'black' },
        { label: 'Адрес доставки', title: this.order.address, color: 'black' },
        { label: 'Если товара не будет в наличии', title: 'Заменить товар на другой', color: 'black' },
        { label: 'Статус оплаты', title: this.order.order_status.name, color: getColorStatus(this.order.order_status.name) },
      ];
    },
  },
  methods: {
    getBackgroundStatus() {
      return getBackgroundStatus
    },
    async fetchOrder() {
      try {
        const result = await getOrder(this.$route.params.orderId);
        this.order = result.data;
        this.isLoading = false;
      } catch (error) {
        console.error('Error fetching order:', error);
      }
    },
    async fetchUrl() {
      try {
        const result = await getUrl();
        this.url = result.data;
      } catch (error) {
        console.error('Error fetching URL:', error);
      }
    },
    async cancelOrderFunction(id) {
      await cancelOrder(id);
      this.isLoading = true;
      await this.fetchOrder();
    },
    navigateToProfile() {
      this.$router.push({ path: '/profile', query: { tab: 4 } });
    },
  },
  async mounted() {
    await this.fetchUrl();
    await this.fetchOrder();
    window.scrollTo({ top: 0 });
  },
};
</script>

<style lang="scss">
.order-page {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 28px;
  }

  &__back {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__header-text {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__info {
    display: flex;
    gap: 12px;
  }

  &__details {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 28px;
  }

  &__details-table {
    display: flex;
    flex-direction: column;
    padding: 0 28px;
    background-color: var(--gray-FB);
  }

  &__details-row{
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 14px 0;
    border-bottom: 1px solid var(--gray-DD);
    &:last-child {
      border: none
    }
  }

  &__controls {
    display: flex;
    gap: 20px;
  }

  &__product-container {
    display: flex;
    flex-direction: column;
    gap: 28px;
  }

  &__product-list {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 433px);
    width: 100%;
  }
}
</style>
