<template>
  <div class="shop-card-container">
    <div class="shop-card" @click="goToProduct">
      <div class="shop-card__image-container">
        <div v-if="product.discount !== 0" class="shop-card__price-discount-frame">
          <Text type="t500w12px" color="white" :label="`-${product.discount}%`"/>
        </div>
        <div class="shop-card__like-button" @click.stop="handleLikeClick">
          <img v-if="isFavorite" src="@/assets/icons/base/heartActive.svg" alt="">
          <img v-else src="@/assets/icons/base/heart.svg" alt="">
        </div>
        <img v-if="product.photo_url" :src="`${apiURL}${product.photo_url}`" width="100%" height="100%" alt="ProductCardImage" />
      </div>
      <div class="shop-card__info-container">
        <div class="shop-card__rating-category">
          <div class="shop-card__category">
            <Text type="t500w10px" color="gray" :label="product.subcategory.name_ru"/>
          </div>
        </div>
        <div class="shop-card__name">
          <Text type="t500w13px" color="black" :label="product.name_ru"/>
        </div>
        <div class="shop-card__price-button">
          <div class="shop-card__price">
            <Text type="t600w17px" color="black" :label="`${product.price_with_discount} ${currency.KZ}`"/>
            <div v-if="product.discount !== 0" class="shop-card__price-discount">
              <Text type="t400w15px" color="gray" :label="`${product.price} ${currency.KZ}`"/>
            </div>
          </div>
          <div class="shop-card__cart-button" @mouseenter="isShownDelete = true" @mouseleave="isShownDelete = false">
            <Button
                v-if="isInCart(product.id)"
                :color="isShownDelete ? 'secondaryRed' : 'primaryGrey'"
                @click.stop="removeFromCartHandler(product.id)"
            >
              <template #icon>
                <div style="height: 16px; width: 16px">
                  <img v-if="isShownDelete" src="@/assets/icons/base/trashRed.svg" alt="?">
                  <img v-else src="@/assets/icons/base/checkCart.svg" alt="?">
                </div>
              </template>
            </Button>
            <Button
                v-else
                color="secondaryGreen"
                @click.stop="openCountModal"
            >
              <template #icon>
                <img src="@/assets/icons/base/cart.svg" alt="">
              </template>
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import currency from "@/assets/data/currency/currency";
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: 'ShopCard',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isCountModalOpen: false,
      isShownDelete: false,
      apiURL: process.env.VUE_APP_API_URL,
    };
  },
  computed: {
    ...mapGetters('favorite', ['checkFavorite']),
    ...mapState(['isLoggedIn']),
    isFavorite() {
      return this.checkFavorite(this.product.id);
    },
    currency() {
      return currency;
    }
  },
  methods: {
    ...mapActions('favorite', ['addToFavoriteHandler', 'removeFromFavoriteHandler']),
    ...mapActions('loginModal', ['showModal']),
    isInCart(id) {
      return id % 2 === 1;
    },
    openCountModal() {
      this.isCountModalOpen = true;
    },
    async handleLikeClick(e) {
      e.stopPropagation();
      if (this.isLoggedIn){
        if (this.isFavorite) {
          await this.removeFromFavoriteHandler(this.product.id);
        } else {
          await this.addToFavoriteHandler(this.product);
        }
      } else {
        await this.showModal()
      }
    },
  }
};
</script>

<style lang="scss">
.shop-card-container{
  display: flex;
  justify-content: center;
  align-items: center;
}

.shop-card {
  display: flex;
  flex-direction: column;
  width: 212px;
  border-radius: 8px;
  cursor: pointer;
  background-color: var(--gray-FB);
  position: relative;

  &__image-container {
    width: 100%;
    height: 212px;
  }

  &__price-discount-frame {
    width: 39px;
    height: 18px;
    top: 15px;
    border-radius: 0 9px 9px 0;
    background-color: var(--orange-100);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__info-container {
    padding: 10px;
  }

  &__rating-category {
    display: flex;
    justify-content: space-between;
  }

  &__name {
    margin-top: 5px;
    font-size: 14px;
    line-height: 18px;
    height: 36px;
    overflow: hidden;
  }

  &__price-button {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }

  &__cart-button {
    display: flex;
    align-self: flex-end;
  }

  &__price-discount {
    text-decoration: line-through;
  }

  &__price {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  &__like-button {
    width: 20px;
    height: 20px;
    top: 14px;
    right: 0;
    position: absolute;
  }

  @media (max-width: 750px) {
    width: 178px;
  }
}
</style>