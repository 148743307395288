<script>
import {mediaQuery} from "@/utils/helpers/mediaQuery";
import Dropdown from "@/components/base/dropdown/Dropdown.vue";

export default {
  name: "CatalogHeader",
  components: {Dropdown},
  props: {
    title: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
  },
  data(){
    return{
      isMobile: mediaQuery('(max-width: 750px)'),
      dropList: [
        { id: 0, title: 'Цена по возростанию' },
        { id: 1, title: 'Цена по уменьшению' },
      ]
    }
  },
};
</script>

<template>
  <div class="catalogHeaderContainer">
    <div class="catalogHeaderText">
      <Text :type="'h700w20px'" :label="title" />
      <Text type="t500w15px" :label="`Найдено ${count} товаров`" color="gray" />
    </div>
    <Dropdown v-if="!isMobile" :list="dropList" width="unset" />
  </div>
</template>

<style scoped lang="scss">
.catalogHeaderContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
</style>