import { createFavorite, deleteFavorite, getFavorite } from "@/http/favorite/favorite";
import { getCookie } from "@/utils/helpers/cookies.helpers";
import { useStore } from 'vuex';

export const favorite = {
    namespaced: true,
    state: {
        favorite_form: {
            list: [],
            isEmpty: true,
        }
    },
    mutations: {
        SET_FAVORITE_LIST(state, list) {
            state.favorite_form.list = list;
            state.favorite_form.isEmpty = list.length === 0;
        },
        ADD_TO_FAVORITE(state, product) {
            const newFavorite = {
                product,
                id: 0,
                created_at: null,
                product_id: product.id,
                updated_at: null,
                user_id: getCookie('USER_ID')
            };
            state.favorite_form.list.push(newFavorite);
            state.favorite_form.isEmpty = false;
        },
        REMOVE_FROM_FAVORITE(state, id) {
            state.favorite_form.list = state.favorite_form.list.filter((favoriteItem) => favoriteItem.product_id !== id);
            state.favorite_form.isEmpty = state.favorite_form.list.length === 0;
        },
        RESET_FAVORITE(state) {
            state.favorite_form.list = [];
            state.favorite_form.isEmpty = true;
        },
    },
    actions: {
        async fetchFavorite({ commit }) {
            try {
                const response = await getFavorite();
                if (response.success) {
                    commit('SET_FAVORITE_LIST', response.data);
                } else {
                    console.log('ERROR:', response.data);
                    commit('SET_FAVORITE_LIST', []);
                }
            } catch (error) {
                console.log('ERROR:', error.message);
                commit('SET_FAVORITE_LIST', []);
            }
        },
        async addToFavoriteHandler({ commit, state }, product) {
            console.log(product)
            commit('ADD_TO_FAVORITE', product);

            try {
                await createFavorite(product.id);
            } catch (error) {
                commit('SET_FAVORITE_LIST', state.favorite_form.list.filter((item) => item.product_id !== product.id));
            }
        },
        async removeFromFavoriteHandler({ commit }, id) {
            commit('REMOVE_FROM_FAVORITE', id);

            try {
                await deleteFavorite(id);
            } catch (error) {
                this.dispatch('fetchFavorite');
            }
        },
        resetFavorite({ commit }) {
            commit('RESET_FAVORITE');
        },
    },
    getters: {
        checkFavorite: (state) => (id) => {
            return !!state.favorite_form.list.find(favoriteItem => favoriteItem.product_id === id);
        },
    },
};
