import httpClient from "@/services/http.service";

export const getSubCategory = async () => {
    try {
        const { data, status } = await httpClient.get('api/sub-category/index');
        if (status === 200) {
            return data.data;
        }
    } catch (error) {
        return error.response;
    }
};

export const addSubCategory = async (data) => {
    try {
        const { data, status } = await httpClient.post('api/sub-category/store', data);
        if (status === 200) {
            return data.data;
        }
    } catch (error) {
        return error.response;
    }
};

export const editSubCategory = async (data, id) => {
    try {
        const { data, status } = await httpClient.post(`api/sub-category/update/${id}`, data);
        if (status === 200) {
            return data.data;
        }
    } catch (error) {
        return error.response;
    }
};

export const deleteSubCategory = async (id) => {
    try {
        const { data, status } = await httpClient.delete(`api/sub-category/delete/${id}`);
        if (status === 200) {
            return data.data;
        }
    } catch (error) {
        return error.response;
    }
};
