<script>
export default {
  name: "CatalogPath",
  components: {
    Text,
  },
  props: {
    firstCategory: {
      type: String,
      required: true,
    },
    secondCategory: {
      type: String,
      default: '',
    },
    thirdCategory: {
      type: String,
      default: '',
    },
    isFirstBlack: {
      type: Boolean,
      default: false,
    },
    onClickSecond: {
      type: Function,
      default: null,
    },
  },
};
</script>

<template>
  <div class="catalogPathContainer">
    <Text :type="'t500w15px'" :label="firstCategory" :color="isFirstBlack ? 'black' : 'green'" />
    <div v-if="secondCategory" class="catalogPath__link" @click="onClickSecond">
      <img src="@/assets/icons/base/rightArrow.svg" alt=">">
      <Text type="t500w15px" :label="secondCategory" color="green" />
    </div>
    <div v-if="thirdCategory" class="catalogPath__link">
      <img src="@/assets/icons/base/rightArrow.svg" alt=">">
      <Text type="t500w15px" :label="thirdCategory" color="green" />
    </div>
  </div>
</template>


<style scoped>
.catalogPathContainer {
  display: flex;
  align-items: center;
}

.catalogPath__link {
  display: flex;
  align-items: center;
  cursor: pointer;
}
</style>
