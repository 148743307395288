<template>
  <div>
    <div v-if="!order_form.isEmpty" class="order-tab__container">
      <div class="order-tab__content">
        <div
            v-for="order in order_form.list"
            :key="order.id"
            class="order-tab__item"
            @click="navigateToOrder(order.id)"
        >
          <div class="order-tab__item-header">
            <Text :type="'t600w17px'" :label="'Заказ №' + order.id" link />
            <Text
                :type="'t600w17px'"
                :label="order.total_price.toLocaleString('ru-RU') + ' ' + currency.KZ"
                link
            />
          </div>
          <div class="order-tab__item-status-info">
            <Text
                :type="'t500w15px'"
                :label="'Оформлен: ' + getFormattedDate(order.created_at)"
                color="gray"
                link
            />
            <div class="order-tab__item-status-text">
              <Text type="t500w15px" label="Статус:" color="gray" link />
              <Text
                  :type="'t500w15px'"
                  :label="order.order_status.name"
                  :color="getColorStatus(order.order_status.name)"
                  link
              />
            </div>
          </div>
          <Badge
              :background-color="getBackgroundStatus(order.order_status.name)"
              :title="order.order_status.name"
              :color="order.order_status.name === 'Ожидание' ? 'black' : 'white'"
          />
        </div>
      </div>
      <div v-if="order_form.list.length === 0" class="order-tab__no-orders">
        <img src="@/assets/images/bee/surprised.svg" alt="">
        <Text type="t600w15px" label="Список заказов пуст" />
        <Button title="Перейти на главную" @click="navigateToHome" color="primaryGreen" />
      </div>
    </div>
    <div v-else class="order-tab__loading">
      <Spinner />
    </div>
  </div>
</template>

<script>
import {getBackgroundStatus, getColorStatus, getFormattedDate} from "@/utils/functions/functions";
import {mapState} from "vuex";
import Spinner from "@/components/base/spinner/Spinner.vue";
import Badge from "@/components/base/badge/Badge.vue";
import currency from "@/assets/data/currency/currency";

export default {
  name: 'OrderTab',
  components: {
    Badge,
    Spinner,
  },
  computed:{
    ...mapState('order', ['order_form']),
    currency() {
      return currency
    },
  },
  methods: {
    getBackgroundStatus,
    getColorStatus,
    getFormattedDate,
    navigateToOrder(orderId) {
      this.$router.push(`/order/${orderId}`);
    },
    navigateToHome() {
      this.$router.push('/');
    },
  },
};
</script>

<style lang="scss">
.order-tab {
  &__container {
    padding: 14px;
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    padding: 14px;
    gap: 14px;
    border-radius: 6px;
    background-color: var(--gray-FB);
    cursor: pointer;
  }

  &__item-header {
    display: flex;
    justify-content: space-between;
  }

  &__item-status-info {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &__item-status-text {
    display: flex;
    gap: 5px;
  }

  &__no-orders {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 14px;
  }

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 433px);
    width: 100%;
  }
}
</style>
