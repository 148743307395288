import { setCookie, getCookie, checkCookieVariable, deleteCookie } from '@/utils/helpers/cookies.helpers';

export const cart = {
    namespaced: true,
    state: {
        cart: {
            list: [],
            isEmpty: false,
        },
        similarProducts: [],
    },
    mutations: {
        SET_CART(state, payload) {
            state.cart.list = payload.list;
            state.similarProducts = payload.similarProducts;
            state.cart.isEmpty = payload.isEmpty;
        },
        ADD_TO_CART(state, payload) {
            const existingItem = state.cart.list.find(cartItem => cartItem.product.id === payload.product.id);
            if (existingItem) {
                state.cart.list = state.cart.list.map(cartItem =>
                    cartItem.product.id === payload.product.id
                        ? { ...cartItem, product_quantity: payload.quantity }
                        : cartItem
                );
            } else {
                state.cart.list.push({
                    created_at: null,
                    id: 0,
                    product: payload.product,
                    product_id: payload.product.id,
                    product_quantity: payload.quantity,
                    updated_at: null,
                });
            }
            state.cart.isEmpty = false;
            setCookie('cart', JSON.stringify(state.cart.list));
        },
        REMOVE_FROM_CART(state, productId) {
            state.cart.list = state.cart.list.filter(cartItem => cartItem.product_id !== productId);
            if (state.cart.list.length === 0) {
                state.cart.isEmpty = true;
            }
            setCookie('cart', JSON.stringify(state.cart.list));
        },
        RESET_CART(state) {
            state.cart.list = [];
            state.cart.isEmpty = true;
            deleteCookie('cart');
        },
    },
    actions: {
        fetchCart({ commit }) {
            if (checkCookieVariable('cart')) {
                const cartData = getCookie('cart');
                commit('SET_CART', {
                    list: JSON.parse(cartData),
                    similarProducts: [],  // Замените на реальные данные, если они нужны
                    isEmpty: false,
                });
            } else {
                commit('SET_CART', { list: [], similarProducts: [], isEmpty: true });
            }
        },

        addToCart({ commit }, { product, quantity }) {
            commit('ADD_TO_CART', { product, quantity });
        },

        removeFromCart({ commit }, productId) {
            commit('REMOVE_FROM_CART', productId);
        },

        resetCart({ commit }) {
            commit('RESET_CART');
        }
    }
};
