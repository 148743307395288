<template>
  <div v-if="isVisible" class="login-modal" @click="closeModal">
    <div class="login-modal__container" @click.stop>
      <div class="login-modal__header">
        <div class="login-modal__close-icon" @click="closeModal">
          <img src="@/assets/icons/base/cross.svg" alt="cross">
        </div>
      </div>
      <div class="login-modal__content">
        <img src="@/assets/images/bee/smiling.svg" alt="smiling">
        <div class="login-modal__text">
          <Text type="t600w15px" label="Вы не авторизованы!"/>
          <Text type="t500w15px" color="gray" label="Авторизируйтесь, чтобы получить доступ ко всем функциям сайта"/>
        </div>
      </div>
      <Button
          title="Авторизоваться"
          color="primaryGreen"
          width="100%"
          @click="goToLogin"
      />
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';

export default {
  name: 'LoginModal',
  computed: {
    ...mapState('loginModal', ['isVisible'])
  },
  methods: {
    ...mapActions('loginModal', ['showModal', 'hideModal']),
    closeModal() {
      this.hideModal()
    },
    goToLogin() {
      this.$router.push('/login');
      this.closeModal();
    }
  }
};
</script>

<style lang="scss">

.login-modal {
  &__container {
    display: flex;
    flex-direction: column;
    background-color: var(--white-100);
    border-radius: 8px;
    padding: 14px;
    gap: 20px;
    width: 360px;
  }

  &__header {
    display: flex;
    justify-content: flex-end;
  }

  &__close-icon {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    text-align: center;
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  & {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    background-color: var(--modal-background);
  }
}
</style>
