<template>
  <div class="profile-page">
    <Text type="h700w24px" :label="tabs.find(tab => tab.id === selected).title"/>
    <div class="profile-page__layout">
      <div class="profile-tab">
        <div v-if="!isMobile" class="profile-tab__nav">
          <div>
            <div
                v-for="(tab, index) in tabs"
                :key="tab.id"
                :class="['profile-tab__item', { 'profile-tab__item--selected': selected === tab.id }]"
                @click="switchTab(tab)"
                :style="{ borderRadius: selected === tab.id && index === 0 ? '8px 0 0 0' : '' }"
            >
              <BaseIcon width="16" height="16" :name="tab.icon" :color="selected === tab.id ? '#53C95A' : '#8B8B8B'"/>
              <Text :type="'t600w15px'" :color="selected === tab.id ? 'green' : 'gray'" :label="tab.title" link />
            </div>
          </div>
          <div class="profile-tab__exit" @click="logOut">
            <img src="@/assets/icons/mobile/exit.svg" alt="?">
            <Text type="t500w15px" color="red" label="Выход" link />
          </div>
        </div>
        <div v-else class="profile-tab__nav--mobile">
          <div
              v-for="(tab, index) in tabs"
              :key="tab.id"
              :class="['profile-tab__item', 'profile-tab__item--mobile', { 'profile-tab__item--selected': selected === tab.id }]"
              @click="switchTab(tab)"
              :style="{ borderRadius: selected === tab.id && index === 0 ? '8px 0 0 0' : '' }"
          >
            <BaseIcon width="16" height="16" :name="tab.icon" :color="selected === tab.id ? '#53C95A' : '#8B8B8B'"/>
          </div>
        </div>
        <div class="profile-tab__wrapper" :class="{'profile-tab__wrapper--active': tab.id === selected}" v-for="tab in tabs" :key="tab.id">
          <div class="profile-tab__wrapper-inner" v-if="tab.id === selected">
            <component :is="tab.content" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Text from "@/components/base/text/Text.vue";
import {mediaQuery} from "@/utils/helpers/mediaQuery";
import authService from "@/services/auth.service";
import Orders from "@/components/profile/Orders.vue";
import Favorites from "@/components/profile/Favorites.vue";
import Profile from "@/components/profile/Profile.vue";
import BaseIcon from "@/components/base/base-icon/BaseIcon.vue";
import {mapActions} from "vuex";

export default {
  name: 'ProfileTab',
  components: {BaseIcon, Text},
  data() {
    return {
      selected: 1,
      tabs: [
        { id: 1, tab: 'profile', title: 'Профиль', content: Profile, icon: 'profile' },
        { id: 2, tab: 'favorite', title: 'Избранное', content: Favorites, icon: 'heart' },
        { id: 3, tab: 'orders', title: 'Заказы', content: Orders, icon: 'orders' },
      ],
      isMobile: mediaQuery('(max-width: 900px)')
    };
  },
  methods: {
    ...mapActions(['updateLoginStatus']),
    switchTab(tab) {
      this.selected = tab.id;
    },
    async logOut() {
      await authService.logout()
      await this.updateLoginStatus()
      this.$router.push('/');
    }
  }
};
</script>

<style lang="scss">
.profile-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 14px;

  &__layout {
    height: 100%;
  }
}

.profile-tab {
  display: flex;
  height: 100%;
  width: 100%;
  border: 1px solid var(--gray-F5);
  border-radius: 8px;

  &__nav {
    display: flex;
    flex-direction: column;
    min-width: fit-content;
    width: 176px;
    justify-content: space-between;
    cursor: pointer;
    min-height: 560px;
    border-right: 1px solid var(--gray-F5);
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 14px;
    border-bottom: 1px solid;
    border-image: linear-gradient(to right, transparent 22%, var(--gray-F5) 22%) 1;
  }

  &__item--selected {
    background-color: var(--profile-tab-selected-background);
  }

  &__exit {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 14px;
    cursor: pointer;
  }

  &__nav--mobile {
    display: flex;
    position: fixed;
    background-color: var(--white-100);
    bottom: 0;
    left: 0;
    width: 100vw;
    z-index: 1;
  }

  &__item--mobile {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
  }

  &__wrapper{
    display: flex;
  }

  &__wrapper--active{
    width: 100%;
  }

  &__wrapper-inner{
    width: 100%;
  }

  @media (max-width: 900px) {
    & {
      border: none;
      justify-content: center;
    }
    .profileSettingsContainer {
      width: 100%;
    }
    .profileSettingsRow {
      flex-direction: column;
    }
  }
}
</style>
