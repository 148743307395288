<template>
  <div class="slider__container" @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd">
    <div class="slider__skeleton" :class="{ 'slider__skeleton--hidden': firstImageLoaded }"></div>
    <div class="slider">
      <div class="slider__wrapper">
        <div class="slider__slides" :style="{ transform: `translateX(-${currentIndex * 100}%)` }">
          <div
              v-for="(slide, index) in slides"
              :key="slide.id"
              class="slider__slide"
          >
            <img
                :src="API_ENDPOINT() + slide.image_url_ru"
                alt="Banner"
                class="slider__image"
                @load="onImageLoad(index)"
            />
          </div>
        </div>
        <button @click="prevSlide" class="slider__button slider__button--left">
          <img src="../../../assets/icons/base/left-circle-arrow.svg" alt="Previous" />
        </button>
        <button @click="nextSlide" class="slider__button slider__button--right">
          <img src="../../../assets/icons/base/right-circle-arrow.svg" alt="Next" />
        </button>
        <div class="slider__indicators">
          <div
              v-for="(slide, index) in slides"
              :key="slide.id"
              :class="['slider__indicator', { 'slider__indicator--active': index === currentIndex }]"
              @click="goToSlide(index)"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API_ENDPOINT } from "@/services/http.service";

export default {
  name: "Slider",
  data() {
    return {
      currentIndex: 0,
      firstImageLoaded: false,
      loadedCount: 0,
      startX: 0,
      isSwiping: false, // Флаг для управления свайпами
    };
  },
  props: {
    slides: {
      required: true,
      type: Array,
    }
  },
  methods: {
    API_ENDPOINT() {
      return API_ENDPOINT;
    },
    prevSlide() {
      this.currentIndex = (this.currentIndex - 1 + this.slides.length) % this.slides.length;
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.slides.length;
    },
    goToSlide(index) {
      this.currentIndex = index;
    },
    onImageLoad(index) {
      if (index === 0) {
        this.firstImageLoaded = true;
      }
      this.loadedCount++;
      if (this.loadedCount === this.slides.length) {
        this.imagesLoaded = true;
      }
    },
    onTouchStart(event) {
      this.startX = event.touches[0].clientX;
      this.isSwiping = true;
    },
    onTouchMove(event) {
      if (!this.isSwiping) return;

      const deltaX = event.touches[0].clientX - this.startX;
      if (deltaX > 50) {
        this.prevSlide();
        this.isSwiping = false;
      } else if (deltaX < -50) {
        this.nextSlide();
        this.isSwiping = false;
      }
    },
    onTouchEnd() {
      this.isSwiping = false;
    },
  },
};
</script>

<style lang="scss">
.slider__container {
  position: relative;
  width: 100%;
  overflow: hidden;
  min-height: 120px;
}

.slider__skeleton {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 120px;
  border-radius: 5px;
  animation: shimmer 2s infinite;
  z-index: 2;
  pointer-events: none;

  &--hidden {
    display: none;
  }

  @keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
  background: linear-gradient(
          to right,
          #e0e0e0 0%,
          #f0f0f0 50%,
          #e0e0e0 100%
  );
  background-size: 200% 100%;
}

.slider {
  position: relative;
  z-index: 1;
  width: 100%;

  &__wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  &__slides {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 100%;
    height: 100%;
  }

  &__slide {
    flex: 0 0 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  &__button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;

    &--left {
      left: 10px;
    }

    &--right {
      right: 10px;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }

  &__indicators {
    position: absolute;
    bottom: 14px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
  }

  &__indicator {
    width: 8px;
    height: 8px;
    background-color: #ccc;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;

    &--active {
      background-color: #333;
    }
  }
}
</style>