<template>
  <div class="not-found">
    <img src="@/assets/images/bee/surprised.svg" alt="surprised">
    <Text type="t600w15px" :label="title"/>
    <Button title="Вернуться на главную" color="primaryGreen" @click="goHome" />
  </div>
</template>

<script>

export default {
  name: 'NotFound',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    goHome() {
      this.$router.push('/');
    },
  },
};
</script>

<style lang="scss">

.not-found {
  display: flex;
  flex-direction: column;
  gap: 14px;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media (max-width: 900px) {
    height: 65vh;
  }
}
</style>
