<template>
  <div class="checkboxContainer">
    <input
        id="checkLabel"
        class="checkboxInput"
        type="checkbox"
        v-model="stateRef"
        @change="handleChange"
    />
    <label for="checkLabel" class="checkboxLabel">
      <img v-if="stateRef" class="checkboxIcon" src="../../../assets/icons/base/check.svg" width="16" height="16" alt="Check Icon"/>
    </label>
    <label for="checkLabel" class="checkboxLabelText">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    state: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    }
  },
  emits: ['update:state'],
  data() {
    return {
      stateRef: this.state
    };
  },
  watch: {
    state(newVal) {
      this.stateRef = newVal;
    }
  },
  methods: {
    handleChange() {
      this.$emit('update:state', this.stateRef);
    }
  }
};
</script>

<style scoped>
.checkboxIcon {
  fill: red;
}

.checkboxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.checkboxInput {
  display: none;
}

.checkboxLabel {
  cursor: pointer;
  user-select: none;
  width: 16px;
  height: 16px;
  border-radius: 6px;
  border: 1px solid var(--gray-00);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white-100);
  position: relative;
}

.checkboxLabelText {
  user-select: none;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  color: var(--gray-8B);
}

.checkboxInput:checked + .checkboxLabel {
  border-color: var(--blue-100);
}
</style>
