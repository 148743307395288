export function getMonth(monthNumber) {
    switch (monthNumber) {
        case '01':
            return 'Января';
        case '02':
            return 'Февраля';
        case '03':
            return 'Марта';
        case '04':
            return 'Апреля';
        case '05':
            return 'Мая';
        case '06':
            return 'Июня';
        case '07':
            return 'Июля';
        case '08':
            return 'Августа';
        case '09':
            return 'Сентября';
        case '10':
            return 'Октября';
        case '11':
            return 'Ноября';
        case '12':
            return 'Декабря';
        default:
            return 'Не найдено';
    }
}

export function getBackgroundStatus(status) {
    switch (status) {
        case 'Ожидание':
            return '#EEEEEE';
        case 'Отменён':
            return '#FF4C4C';
        case 'Доставлен':
            return '#53C95A';
        case 'Не оплачен':
            return '#FF4C4C';
        default:
            return '';
    }
}

export function getColorStatus(status) {
    switch (status) {
        case 'Ожидает оплаты':
            return 'black';
        case 'Не оплачен':
            return 'red';
        case 'Оплачен':
            return 'green';
        default:
            return 'black';
    }
}

export function getFormattedDate(dateString) {
    const date = dateString.split('T')[0].split('-');
    const formattedData = `${date[2]} ${getMonth(date[1])} ${date[0]}`;
    return `${formattedData} г.`;
}

export function getFormattedDateTime(dateString) {
    if (!dateString) {
        return "Не указано";
    }
    try {
        const date = dateString.split('T')[0].split('-');
        const time = dateString.split('T')[1].split('.')[0];
        const formattedData = `${date[2]} ${getMonth(date[1])} ${date[0]}`;
        return `${formattedData}, ${time}`;
    } catch (error) {
        console.error('Error formatting date:', error);
        return "Не указано";
    }
}

export function getFileName(path) {
    if (path) {
        const stringAr = path.split('/');
        return stringAr[stringAr.length - 1];
    } else {
        return 'Не указано';
    }
}

export function getCategoryFromId(category_id, categoriesArray) {
    const category = categoriesArray.find((category) => category.id === category_id);
    return category ? category.name_ru : 'Не найдено';
}
