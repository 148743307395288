<template>
  <div class="spinner__container">
    <div class="spinner__content">
      <img src="../../../assets/icons/base/spinner.svg" alt="?">
    </div>
  </div>
</template>

<script>

export default {
  name: 'Spinner',
};
</script>

<style lang="scss">
.spinner {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 1s ease;
    animation: rotate 1s ease-in-out infinite;
  }

  @keyframes rotate {
    from {
      transform: rotate(-360deg);
    }
  }
}
</style>
