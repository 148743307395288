<template>
  <div class="search-component__container" :style="{ width: width }">
    <input
        class="search-component__input"
        :placeholder="placeholder"
        v-model="value"
        @input="changeHandler"
        @keydown="searchFunction"
        :maxlength="maxLength"
    />
    <img src="../../../assets/icons/base/search.svg" alt="Search" class="search-component__image" @click="onSearch(value)" />
  </div>
</template>

<script>

export default {
  name: 'Search',
  props: {
    placeholder: {
      type: String,
      required: true
    },
    width: {
      type: String,
      default: ''
    },
    onChange: {
      type: Function,
      required: true
    },
    onSearch: {
      type: Function,
      required: true
    },
    maxLength: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      value: ''
    };
  },
  methods: {
    changeHandler(event) {
      this.onChange(event.target.value);
      this.value = event.target.value;
    },
    searchFunction(event) {
      if (event.keyCode === 13 && this.value !== '') {
        this.onSearch(this.value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>

.search-component {
  &__container {
    display: flex;
    background-color: var(--gray-F5);
    border-radius: 8px;
    position: relative;
    border: 1px solid var(--gray-F5);
    transition: 0.2s;

    &:focus-within {
      background-color: transparent;
      border: 1px solid var(--blue-50);
    }
  }

  &__input {
    border: 0;
    background-color: transparent;
    width: 100%;
    padding: 13px 42px 13px 13px;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings: "slnt" 0;
    font-weight: 500;
    font-size: 15px;
    color: var(--gray-00);

    &::placeholder {
      font-family: "Inter", sans-serif;
      font-optical-sizing: auto;
      font-style: normal;
      font-variation-settings: "slnt" 0;
      font-weight: 500;
      font-size: 15px;
      color: var(--gray-4F);
    }

    &:focus {
      outline: none;
      border-color: var(--blue-50);
    }

  }
  &__image {
    position: absolute;
    right: 13px;
    top: 13px;
    cursor: pointer;
  }
}
</style>
