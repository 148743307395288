<template>
  <div class="badge" :style="{ backgroundColor }">
    <Text
        type="t500w13px"
        :label="title"
        :color="color"
        link
    />
  </div>
</template>

<script>

export default {
  name: 'Badge',
  props: {
    backgroundColor: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      validator(value) {
        return ['black', 'gray', 'green', 'red', 'white', undefined].includes(value);
      },
    },
  },
};
</script>

<style lang="scss">
.badge {
  width: fit-content;
  border-radius: 50px;
  padding: 4px 8px;
}
</style>
