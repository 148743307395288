<template>
  <div :class="['total-table', { 'total-table--mobile': isMobile }]" :style="{ padding: isPadding }">
    <div v-if="header" class="total-table__header">
      <Text type="h700w24px">{{ header }}</Text>
    </div>
    <div class="total-table__check">
      <div class="total-table__check-row">
        <Text type="t500w15px">Продукты</Text>
        <Text type="t500w15px">{{ price }} {{ currency }}</Text>
      </div>
      <div class="total-table__check-row">
        <Text type="t500w15px">Стоимость доставки</Text>
        <Text type="t500w15px">{{ deliveryCost === 0 ? 'Бесплатно' : deliveryCost }} {{ currency }}</Text>
      </div>
      <div v-if="tipsCount" class="total-table__check-row">
        <Text type="t500w15px">Чаевые</Text>
        <Text type="t500w15px">{{ tipsCount }} {{ currency }}</Text>
      </div>
    </div>
    <div class="total-table__row">
      <Text type="t600w17px">Итоговая сумма</Text>
      <Text type="t600w17px">{{ totalPrice }} {{ currency }}</Text>
    </div>
    <div v-if="header" class="total-table__control">
      <Button
          title="Перейти к оформлению"
          color="primaryGreen"
          width="100%"
          @click="onClickButton"
          :disabled="buttonDisabled"
      />
    </div>
  </div>
</template>

<script>
import {mediaQuery} from "@/utils/helpers/mediaQuery";

export default {
  name: 'TotalTable',
  props: {
    header: {
      type: String,
      required: false
    },
    price: {
      type: Number,
      required: true
    },
    currency: {
      type: String,
      required: true
    },
    deliveryCost: {
      type: Number,
      required: true
    },
    totalPrice: {
      type: Number,
      required: true
    },
    tipsCount: {
      type: Number,
      required: false
    },
    onClickButton: {
      type: Function,
      required: false
    },
    buttonDisabled: {
      type: Boolean,
      required: false
    },
  },
  data(){
    return{
      isMobile: mediaQuery('(max-width: 900px)'),
      isPadding: !this.isMobile && this.header ? '0 28px' : '',
    }
  },
}
</script>

<style lang="scss">

.total-table {
  display: flex;
  flex-direction: column;
  background-color: var(--gray-FB);
  min-width: 364px;
  height: fit-content;
  padding: 28px;
  border-radius: 8px;

  &__header {
    margin-bottom: 28px;
  }

  &__check {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 14px;
    border-bottom: 1px solid var(--gray-DD);
  }

  &__check-row {
    display: flex;
    justify-content: space-between;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
  }

  &__control {
    margin-top: 28px;
  }

  &--mobile {
    padding: 10px;
    min-width: unset;
  }
}
</style>
