<script>
import icons from '@/assets/icons/icons'

export default {
  props: {
    name: {
      required: true,
      type: String,
    },
    color: String,
    height: {
      required: true,
      type: String,
    },
    width: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      path: icons[this.name]
    }
  }
}
// для полного понимания как работает компонента ознокомтесь https://www.youtube.com/watch?v=lKbri9lUNHg&ab_channel=%D0%98%D0%B3%D0%BE%D1%80%D1%8C%D0%91%D0%B0%D0%B1%D0%BA%D0%BE
</script>

<template>
  <svg
      :height="height" :width="width"
      viewBox="0 0 auto auto"
      :fill="color || ''"
      xmlns="http://www.w3.org/2000/svg"
      v-html="path"
  >
  </svg>
</template>

<style scoped>

</style>