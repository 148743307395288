// store/modules/categories.js

import { getCategory } from "@/http/category/catigory";

export const category = {
    namespaced: true,
    state: () => ({
        categories: [],
        isLoading: false,
    }),
    mutations: {
        SET_CATEGORIES(state, categories) {
            state.categories = categories;
        },
        SET_LOADING(state, isLoading) {
            state.isLoading = isLoading;
        },
    },
    actions: {
        async getCategories({ state, commit }) {
            if (state.categories.length === 0 && !state.isLoading) {
                commit('SET_LOADING', true);
                try {
                    const response = await getCategory();
                    if (response.success) {
                        commit('SET_CATEGORIES', response.data);
                    } else {
                        console.error('Failed to fetch categories');
                    }
                } catch (error) {
                    console.error('Error fetching categories:', error);
                } finally {
                    commit('SET_LOADING', false);
                }
            }
        },
    },
};
