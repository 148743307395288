import { createStore } from 'vuex';
import {getCookie} from "@/utils/helpers/cookies.helpers";
import {category} from "@/store/category/category";
import {favorite} from "@/store/favorite/favorite";
import {loginModal} from "@/store/login-modal/login-modal";
import {cart} from "@/store/cart/cart";
import {order} from "@/store/order/order";

export default createStore({
  modules: {
    category,
    favorite,
    loginModal,
    cart,
    order
  },
  state: {
    notifications: [],
    isLoggedIn: false,
  },
  mutations: {
    ADD_NOTIFICATION(state, notification) {
      state.notifications.push(notification);
    },
    REMOVE_NOTIFICATION(state, index) {
      state.notifications.splice(index, 1);
    },
    SET_LOGIN_STATUS(state, status) {
      state.isLoggedIn = status;
    },
  },
  actions: {
    addNotification({ commit }, notification) {
      commit('ADD_NOTIFICATION', notification);
    },
    removeNotification({ commit }, index) {
      commit('REMOVE_NOTIFICATION', index);
    },
    updateLoginStatus({ commit }) {
      const status = !!getCookie('ACCESS_TOKEN');
      console.log('updateLoginStatus', status)
      commit('SET_LOGIN_STATUS', status);
    },
  },
});
