<template>
  <div class="category-slider__container">
    <div class="category-slider" ref="sliderRef" @wheel.prevent="handleWheel">
      <div class="category-slider__slider" :style="{ gap: `${itemsGap || 18}px` }">
        <slot></slot>
      </div>
    </div>
    <div v-if="isOverflowing" class="category-slider__controls">
      <div class="category-slider__control" @click="prev">
        <img class="category-slider__control--button" src="@/assets/icons/base/left-circle-arrow-border.svg" alt="Previous">
      </div>
      <div class="category-slider__control" @click="next">
        <img class="category-slider__control--button" src="@/assets/icons/base/right-circle-arrow-border.svg" alt="Next">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CategorySlider',
  props: {
    itemsGap: {
      type: Number,
      default: 18
    }
  },
  data() {
    return {
      sliderRef: null,
      cardWidth: 0,
      isOverflowing: false
    };
  },
  mounted() {
    this.initializeSlider();
    window.addEventListener('resize', this.checkOverflow);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkOverflow);
  },
  methods: {
    initializeSlider() {
      this.$nextTick(() => {
        this.sliderRef = this.$refs.sliderRef;
        if (this.sliderRef) {
          const firstCard = this.sliderRef.querySelector(':scope > div > div');
          if (firstCard) {
            this.cardWidth = firstCard.offsetWidth + (this.itemsGap || 18);
          }
          this.checkOverflow();
        }
      });
    },
    checkOverflow() {
      if (this.sliderRef) {
        const sliderContentWidth = this.sliderRef.scrollWidth;
        const sliderContainerWidth = this.sliderRef.clientWidth;
        this.isOverflowing = sliderContentWidth > sliderContainerWidth;
      }
    },
    prev() {
      if (this.sliderRef && this.cardWidth) {
        const newScrollLeft = this.sliderRef.scrollLeft - this.cardWidth;
        this.sliderRef.scrollTo({
          left: newScrollLeft,
          behavior: 'smooth'
        });
      }
    },
    next() {
      if (this.sliderRef && this.cardWidth) {
        const newScrollLeft = this.sliderRef.scrollLeft + this.cardWidth;
        this.sliderRef.scrollTo({
          left: newScrollLeft,
          behavior: 'smooth'
        });
      }
    },
    handleWheel(event) {
      if (this.sliderRef) {
        // Чем быстрее прокручивается колесо мыши, тем большее смещение будет
        const scrollAmount = event.deltaY * 2; // Можно настроить множитель для увеличения/уменьшения чувствительности
        this.sliderRef.scrollLeft += scrollAmount;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.category-slider__container {
  position: relative;
  margin-bottom: 11px;

  .category-slider {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 20px;
    }

    &__slider {
      display: flex;
      margin-bottom: 22px;
    }

    &__slide {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__controls {
      position: absolute;
      display: flex;
      gap: 8px;
      width: fit-content;
      right: 0;
      bottom: -11px;
      background-color: #fff;
      padding-left: 10px;

      @media (max-width: 1132px) {
        display: none;
      }
    }

    &__control {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      border: 1px solid #f5f5f5;
      border-radius: 100%;

      &--button {
        cursor: pointer;
      }
    }
  }
}
</style>