<template>
  <div class="notification-container">
    <div
        v-for="(notification, index) in notifications"
        :key="index"
        class="notification"
        :class="notification.type"
    >
      <span>{{ notification.message }}</span>
      <Button @click="removeNotification(index)" size="small" color="transparent">
        <template #icon>
          <img src="../../../assets/icons/base/cross.svg" alt="?" width="10px" height="10px" />
        </template>
      </Button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Button from "@/components/base/button/Button.vue";

export default {
  name: 'Notification',
  components: { Button },
  data() {
    return {
      timers: []
    };
  },
  computed: {
    ...mapState(['notifications']),
  },
  methods: {
    ...mapActions(['removeNotification']),
    scheduleRemoval(index) {
      const timer = setTimeout(() => {
        this.removeNotification(index);
      }, 3000);
      this.timers.push(timer);
    },
    clearTimers() {
      this.timers.forEach(timer => clearTimeout(timer));
      this.timers = [];
    }
  },
  watch: {
    notifications: {
      handler(newNotifications) {
        if (newNotifications.length && this.timers.length < newNotifications.length) {
          this.scheduleRemoval(newNotifications.length - 1);
        }
      },
      deep: true,
      immediate: true,
    }
  },
  unmounted() {
    this.clearTimers();
  }
};
</script>

<style>
.notification-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 998;
}
.notification {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #f8d7da;
  opacity: 0.7;
  border-radius: 10px;
  border: 1px solid #f5c6cb;
  padding: 10px 15px;
  margin-bottom: 5px;
  gap: 10px;
  transition: opacity 1s ease, transform 1s ease;
}
.notification.error {
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.notification.success {
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.notification.fade-out {
  opacity: 0;
  transform: translateY(20px);
}
</style>
