import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/scss/base-styles.scss'
import Notification from '@/components/base/notification/Notification.vue';
import errorHandler from "@/utils/plugins/errorHandler";
import Text from "@/components/base/text/Text.vue";
import Button from "@/components/base/button/Button.vue";
import StyledLink from "@/components/base/styled-link/StyledLink.vue";
import Input from "@/components/base/input/Input.vue";

const app = createApp(App);

app.config.errorHandler = errorHandler;

app.use(store)
app.use(router)
app.component('Notification', Notification);
app.component('Text', Text);
app.component('Button', Button);
app.component('StyledLink', StyledLink);
app.component('Input', Input);
app.mount('#app')