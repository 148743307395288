<template>
  <div
      :style="{ width: width || '293px' }"
      class="dropdown-container"
      :class="{ 'dropdown-container--active': isOpen }"
      @click="toggleDropdown"
  >
    <input class="dropdown-container__input" :value="selectedId" readonly />
    <div class="dropdown-container__text">
      <Text type="t500w15px">
        <template v-if="!multiSelect">
          {{ getTitleSelected() || 'Не выбрано' }}
        </template>
        <template v-else>
          <div v-if="selectedArray.length === 0">Не выбрано</div>
          <div v-else>
            <div v-for="(item, index) in selectedArray" :key="item.id">
              {{ item.id }}<span v-if="index < selectedArray.length - 1">, </span>
            </div>
          </div>
        </template>
      </Text>
    </div>
    <img src="@/assets/icons/base/downArrow.svg" class="dropdown-container__image" alt="downArrow"
         :class="{ 'dropdown-container__image--open': isOpen }"  />
    <div v-if="isOpen" class="dropdown-container__list">
      <div
          v-for="item in list"
          :key="item.id"
          class="dropdown-container__list-item"
          @click="handleItemClick(item)"
      >
        <div class="dropdown-container__list-item-content">
          <Checkbox :state="isSelected(item.id)" />
          <div>{{ item.title }}</div>
        </div>
        <img src="@/assets/icons/base/check.svg" alt="check" v-if="!multiSelect && item.id === selectedId" />
      </div>
    </div>
  </div>
</template>

<script>

import Checkbox from "@/components/base/checkbox/Checkbox.vue";

export default {
  components: {Checkbox},
  props: {
    list: {
      type: Array,
      required: true
    },
    selected: {
      type: String,
      default: ''
    },
    initialId: {
      type: Number,
      default: 1
    },
    multiSelect: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '293px'
    },
    onChange: {
      type: Function,
      default: () => {
      }
    }
  },
  data() {
    return {
      isOpen: false,
      selectedId: this.initialId,
      selectedArray: []
    };
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    handleItemClick(item) {
      if (this.multiSelect) {
        const index = this.selectedArray.findIndex(i => i.id === item.id);
        if (index === -1) {
          this.selectedArray.push(item);
        } else {
          this.selectedArray.splice(index, 1);
        }
      } else {
        this.selectedId = item.id;
        this.onChange(item.id);
      }
    },
    getTitleSelected() {
      const selectedItem = this.list.find(item => item.id === this.selectedId);
      return selectedItem ? selectedItem.title : 'Не выбрано';
    },
    isSelected(id) {
      return this.selectedArray.some(item => item.id === id);
    }
  },
  watch: {
    selected(newVal) {
      const selectedItem = this.list.find(item => item.title === newVal);
      this.selectedId = selectedItem ? selectedItem.id : this.initialId;
    },
    initialId(newVal) {
      this.selectedId = newVal;
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown-container {
  display: flex;
  align-items: center;
  padding: 13px;
  justify-content: space-between;
  cursor: pointer;
  border: 1px solid var(--gray-DD);
  border-radius: 8px;
  position: relative;
  gap: 10px;

  &--active {
    border-color: var(--blue-50);
  }

  &__input {
    display: none;
  }

  &__text {
    user-select: none;
  }

  &__list {
    border-radius: 8px;
    border: 1px solid transparent;
    position: absolute;
    width: 100%;
    left: 0;
    top: calc(100% + 4px);
    box-shadow: 0 3px 16px var(--dropdown-shadow);
    padding: 6px 0;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: var(--white-100);
    z-index: 1;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--gray-DD);
      border-radius: 20px;
    }
  }

  &__list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px;

    &:hover {
      background-color: var(--dropdown-item-background);
    }

    &-content {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  &__image {
    transition: all .3s;

    &--open {
      transform: rotate(180deg);
    }
  }
}
</style>
