<template>
  <footer class="footer">
    <div class="footer__wrapper">
      <div class="footer__row">
        <div class="footer__contacts-nav">
          <StyledLink to="/">
            <img src="@/assets/images/logos/logo-gray.svg" alt="logo-gray">
          </StyledLink>
          <div class="footer__column">
            <Text type="t500w15px" label="Телефон доставки г. Aктобе:"/>
            <Text type="t500w15px" color="green" label="+7 (700) 777 77 77"/>
            <Text type="t500w15px" color="green" label="+7 (700) 555 55 55"/>
            <div class="footer__email">
              <Text type="t500w15px" color="green" label="hello@glowbee.kz"/>
            </div>
          </div>
        </div>
        <div class="footer__social-container">
          <Text type="t600w15px" label="Наши соцсети:"/>
          <div class="footer__social">
            <StyledLink to="https://instagram.com">
              <div class="footer__social-item"><img src="@/assets/icons/social/instagram.svg" alt="instagram"></div>
            </StyledLink>
            <StyledLink to="https://www.whatsapp.com">
              <div class="footer__social-item"><img src="@/assets/icons/social/whatsApp.svg" alt="whatsApp"></div>
            </StyledLink>
            <StyledLink to="https://vk.com">
              <div class="footer__social-item"><img src="@/assets/icons/social/vk.svg" alt="vk"></div>
            </StyledLink>
          </div>
        </div>
      </div>
      <div class="footer__gray-line" />
      <div class="footer__row">
        <div class="footer__privacy">
          <Text type="t500w13px" color="gray" label="Copyright © 2024 GlowBee.kz"/>
          <StyledLink to="/public-offer">
            <Text type="t500w13px" color="gray" link label="Публичная оферта"/>
          </StyledLink>
          <StyledLink to="/user-agreement">
            <Text type="t500w13px" color="gray" link label="Пользовательское соглашение"/>
          </StyledLink>
          <StyledLink to="/privacy-policy">
            <Text type="t500w13px" color="gray" link label="Политика конфиденциальности"/>
          </StyledLink>
        </div>
        <StyledLink to="https://MetaSales">
          <Text type="t500w13px" color="gray" link label="Сайт разработан MetaSales"/>
        </StyledLink>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'Footer',
};
</script>

<style lang="scss">
.footer {
  margin-top: 30px;
  padding: 28px 0;
  display: flex;
  justify-content: center;
  background-color: var(--gray-F5);

  &__wrapper {
    max-width: 1304px;
    width: 100%;
  }

  &__row {
    display: flex;
    justify-content: space-between;
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: 14px;

    &:last-child {
      margin-left: 14px;
    }
  }

  &__email {
    margin-top: 14px;
  }

  &__contacts-nav {
    display: flex;
    gap: 28px;
  }

  &__gray-line {
    height: 1px;
    width: 100%;
    background-color: var(--gray-DD);
    margin: 28px 0 14px 0;
  }

  &__social-container {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }

  &__social {
    display: flex;
    gap: 22px;
  }

  &__social-item {
    background-color: var(--gray-EE);
    padding: 11px 13px;
    border-radius: 100%;
    display: flex;
  }

  &__privacy {
    display: flex;
    gap: 28px;
  }
}

@media (max-width: 1305px) {
  .footer__row {
    padding: 0 10px;
  }
  .footer__gray-line {
    width: auto;
    margin: 14px 10px;
  }
}

@media (max-width: 1005px) {
  .footer__contacts-nav {
    flex-direction: column;
  }
  .footer__column {
    flex-direction: column;
  }
  .footer__column:last-child {
    margin: 0;
  }
}

@media (max-width: 824px) {
  .footer__privacy {
    flex-direction: column;
  }
}

@media (max-width: 600px) {
  .footer__privacy {
    flex-direction: column;
  }
  .footer__row {
    flex-direction: column;
    gap: 28px;
  }
  .footer__social-container {
    align-items: unset;
  }
}
</style>
