import {getCookie} from "@/utils/helpers/cookies.helpers";
import errorHandler from "@/utils/plugins/errorHandler";
import {getOrders} from "@/http/order/order";

export const order = {
    namespaced: true,
    state: {
        order_form: {
            list: [],
            isEmpty: true,
        }
    },
    mutations: {
        SET_ORDERS(state, orders) {
            state.order_form.list = [...state.order_form.list, ...orders];
            state.order_form.isEmpty = false;
        },
        CLEAR_ORDERS(state) {
            state.order_form.list = [];
            state.order_form.isEmpty = true;
        },
        ADD_ORDER(state, order) {
            state.order_form.list = [...state.order_form.list, order];
        }
    },
    actions: {
        async fetchOrders({ commit }) {
            try {
                let response = await getOrders();
                if (response) {
                    if (response.data.message) {
                        console.log('ERROR: ', response.data.message);
                    } else {
                        commit('SET_ORDERS', response.data);
                    }
                } else {
                    commit('CLEAR_ORDERS');
                }
            } catch (error) {
                errorHandler(error);
                commit('CLEAR_ORDERS');
            }
        },
        createOrder({ commit }, product) {
            commit('ADD_ORDER', {
                created_at: null,
                id: 0,
                product,
                product_id: product.id,
                updated_at: null,
                user_id: getCookie('USER_ID'),
            });
        }
    },
    getters: {}
};
