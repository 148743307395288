export const loginModal = {
    namespaced: true,
    state: {
        isVisible: false
    },
    mutations: {
        setIsVisible(state, isVisible) {
            state.isVisible = isVisible;
        }
    },
    actions: {
        showModal({ commit }) {
            commit('setIsVisible', true);
        },
        hideModal({ commit }) {
            commit('setIsVisible', false);
        }
    }
};