<template>
  <div class="order-card" @click="handleClick">
    <img
        v-if="product.photo_url"
        class="order-card__image"
        :src="`${apiURL}${product.photo_url}`"
        alt="Продукт"
    />
    <div v-else class="order-card__image"></div>

    <div class="order-card__row">
      <div class="order-card__title">
        <Text type="t500w15px" :label="product.name_ru" color="green" link />
      </div>
      <div class="order-card__column">
        <Text
            v-if="isMobile"
            type="t600w15px"
            :label="`${product.price_with_discount} ${currency.KZ}`"
            link
        />
        <Text
            v-else
            type="t500w15px"
            :label="`${product.pivot.product_quantity} шт.`"
            color="gray"
            link
        />
        <Text
            v-if="isMobile"
            type="t500w15px"
            :label="`${product.pivot.product_quantity} шт.`"
            color="gray"
            link
        />
        <Text
            v-else
            type="t600w15px"
            :label="`${product.price_with_discount} ${currency.KZ}`"
            link
        />
      </div>
    </div>
  </div>
</template>

<script>

import {mediaQuery} from "@/utils/helpers/mediaQuery";
import currency from "@/assets/data/currency/currency";

export default {
  name: 'OrderCard',
  computed: {
    currency() {
      return currency
    }
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      apiURL: process.env.VUE_APP_API_URL,
      isMobile: mediaQuery('(max-width: 900px)'),
    };
  },
  methods: {
    handleClick() {
      this.$router.push(`product/${this.product.id}`);
    },
  },
};
</script>

<style lang="scss">

.order-card {
  display: flex;
  gap: 14px;
  cursor: pointer;

  &__image {
    width: 100px;
    height: 100px;
    background-color: var(--gray-FB);
  }

  &__row {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__title {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__column {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    text-wrap: nowrap;
  }

  @media (max-width: 900px) {
    &__row {
      flex-direction: column;
      align-items: flex-start;
    }
    &__column {
      flex-direction: row;
      gap: 8px;
    }
  }
}
</style>
