<template>
  <div class="favorite-card">
    <div class="favorite-card__image-wrapper">
      <div class="favorite-card__image">
        <img
            v-if="favoriteProduct.photo_url"
            :src="`${apiURL}${favoriteProduct.photo_url}`"
            alt="Favorite Product"
            width="100%"
        />
        <Text v-else type="h700w24px" color="gray" label="Not Found"/>
      </div>
    </div>
    <div class="favorite-card__content">
      <div class="favorite-card__rate-and-cat">
        <Text type="t500w10px" :label="favoriteProduct.subcategory.name_ru"/>
      </div>
      <Text type="t500w13px" :label="favoriteProduct.name_ru">
        <div class="favorite-card__title" />
      </Text>
      <Text type="t600w17px" :label="favoriteProduct.price_with_discount + ' ' + currency.KZ"/>
      <div class="favorite-card__controls">
        <Button
            v-if="isInCart(favoriteProduct.id)"
            color="primaryGrey"
            @click="removeFromCart(favoriteProduct.id)"
        >
          <template #icon>
            <img src="@/assets/icons/base/checkCart.svg" alt="checkCart">
          </template>
        </Button>
        <Button
            v-else color="secondaryGreen"
            @click="addToCart(favoriteProduct)"
        >
          <template #icon>
            <img src="@/assets/icons/base/cart.svg" alt="cart">
          </template>
        </Button>
        <Button
            title="Удалить"
            width="100%"
            @click="removeFromFavorite(favoriteProduct.id)"
        />
      </div>
    </div>
  </div>
</template>

<script>

import currency from "@/assets/data/currency/currency";
import {mapActions} from "vuex";

export default {
  name: 'FavoriteCard',
  computed: {
    currency() {
      return currency
    }
  },
  props: {
    favoriteProduct: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      apiURL: process.env.VUE_APP_API_URL,
    };
  },
  methods: {
    ...mapActions('favorite', ['removeFromFavoriteHandler']),
    isInCart(id) {
      return id % 2 === 1;
    },
    addToCart(product) {
      // addToCartHandler(null, this.$root.$emit('showModal'), product, 1);
    },
    removeFromCart(id) {
      // removeFromCartHandler(id);
    },
    removeFromFavorite(id) {
      this.removeFromFavoriteHandler(id);
    }
  }
};
</script>

<style lang="scss">

.favorite-card {
  display: flex;
  background-color: var(--gray-FB);
  max-height: 144px;
  max-width: 295px;
  width: 290px;
  border-radius: 8px;

  &__image-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__image {
    width: 140px;
    height: 140px;
    max-width: 140px;
    max-height: 140px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--gray-EE);
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px;
    width: 100%;
  }

  &__rate-and-cat {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
  }

  &__title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__controls {
    display: flex;
    gap: 4px;
  }
}

@media (max-width: 900px) {
  .favorite-card {
    width: 100%;
    max-width: unset;
  }
}
</style>
