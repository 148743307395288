import {checkCookieVariable, deleteCookie, getCookie, setCookie} from '@/utils/helpers/cookies.helpers';
import httpClient from "@/services/http.service";
import router from "@/router";


const authService = {
    getToken() {
        return getCookie('ACCESS_TOKEN')
    },
    async login({phone, code}) {
        try {
            const {status, data}  = await httpClient.post('api/auth/login', {phone, code});
            console.log({status, data})
            if (status === 200){
                setCookie('ACCESS_TOKEN', data.data.token, 86400);
                setCookie('USER_ID', data.data.user.id, 86400);
                const roles = data.data.user.roles.map(role => role.name);
                setCookie('ROLES', roles, 86400);
                return {
                    data: data.data,
                    success: true
                };
            }
        } catch (error) {
            return {
                error: error.response,
                success: false,
            };
        }
    },
    async register(phone) {
        try {
            const {status, data}  = await httpClient.post('api/auth/code', {phone});
            if (status === 200){
                return {
                    data: data.data,
                    success: true
                };
            }
        } catch (error) {
            console.log(error.response)
            return {
                error: error.response,
                success: false,
            };
        }
    },
    async logout() {
        if (checkCookieVariable('ACCESS_TOKEN')) {
            this.deleteAllCookies()
        }
        await router.push({name: 'login'})
    },
    deleteAllCookies() {
        deleteCookie('ACCESS_TOKEN')
        deleteCookie('USER_ID')
        deleteCookie('ROLES')
    }
};

export default authService;
