<template>
  <div class="catalog-page-filter-with-search__container">
    <Text type="t600w15px">{{ header }}</Text>
    <div class="catalog-page-filter-with-search__content">
      <Search
          placeholder="Поиск"
          width="100%"
          :on-change="search"
          :on-search="search"/>
      <div class="catalog-page-filter-with-search__items">
        <div
            v-for="item in listState"
            :key="item.id"
            :class="[
            'catalog-page-filter-with-search__item',
            isSelectedFilter(item.name) ? 'catalog-page-filter-with-search__item--selected' : ''
          ]"
            @click="setSelectedFilters(item.id, item.name)"
        >
          <Text type="t500w12px" :link="true">{{ item.name }}</Text>
        </div>
        <div v-if="listState.length === 0" class="catalog-page-filter-with-search__item--not-found">
          <Text type="t400w15px">Не найдено</Text>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Search from "@/components/base/search/Search.vue";

export default {
  components: {
    Search
  },
  props: {
    header: {
      type: String,
      required: true
    },
    list: {
      type: Array,
      required: true
    },
    selectedFilters: {
      type: Array,
      required: true
    },
    setSelectedFilters: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      listState: this.list
    };
  },
  watch: {
    list(newList) {
      this.listState = newList;
    }
  },
  methods: {
    search(value) {
      if (value === '') {
        this.listState = this.list;
      } else {
        this.listState = this.list.filter((listItem) =>
            listItem.name.includes(value)
        );
      }
    },
    isSelectedFilter(name) {
      return this.selectedFilters.some(
          (filter) => filter.filterName === name
      );
    }
  }
};
</script>

<style lang="scss" scoped>

.catalog-page-filter-with-search {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }

  &__items {
    overflow: scroll;
    overflow-x: hidden;
    display: flex;
    max-height: 160px;
    gap: 2px;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;

    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--gray-DD);
      border-radius: 20px;
    }
  }

  &__item {
    padding: 4.5px 9px;
    background-color: var(--gray-EE);
    border-radius: 8px;
    cursor: pointer;
    height: fit-content;

    &:hover {
      background-color: var(--gray-DD);
    }
  }

  &__item--not-found {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  &__item--selected {

  }
}
</style>
