import httpClient from "@/services/http.service";
import errorHandler from "@/utils/plugins/errorHandler";
export const getPaymentType = async () => {
    try {
        const { data, status } = await httpClient.get('api/payment-type/index');
        if (status === 200) {
            return { success: true, data: data.data };
        }
    } catch (error) {
        errorHandler(error.message);
        return { success: false, data: error.message };
    }
};