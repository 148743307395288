<script>
import {mapActions, mapState} from 'vuex';
import {mediaQuery} from "@/utils/helpers/mediaQuery";
import {getCookie} from "@/utils/helpers/cookies.helpers";
import {getCategory} from "@/http/category/catigory";
import {getSubCategory} from "@/http/sub-category/sub-category";
import BurgerItem from "@/components/base/burger-item/BurgerItem.vue";
import Text from "@/components/base/text/Text.vue";
import Spinner from "@/components/base/spinner/Spinner.vue";
import Button from "@/components/base/button/Button.vue";

export default {
  name: 'BurgerMenu',
  components: {Button, Spinner, Text, BurgerItem},
  data() {
    return {
      isMobile: mediaQuery('(max-width: 768px)'),
      isOpen: false,
      selectedCategory: 0,
      subCategoryList: [],
      loading: true,
    };
  },
  computed: {
    ...mapState(['isLoggedIn']),
    ...mapState('category', ['categories']),
    contentHeight() {
      return this.$route.path === '/profile' ? '59vh' : '80vh';
    },
    filteredSubCategoryList() {
      return this.subCategoryList.filter(subCategory => subCategory?.category_id === this.selectedCategory + 1);
    }
  },
  async mounted() {
    await this.getCategories();
    if (!this.subCategoryList.length){
      await this.fetchSubCategoryList();
    }
    this.loading = false
  },
  methods: {
    ...mapActions('category', ['getCategories']),
    async fetchSubCategoryList() {
      try {
        this.subCategoryList = await getSubCategory();
        console.log(this.subCategoryList)
      } catch (error) {
        console.error('Error fetching subcategory list:', error);
      }
    },
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    selectCategory(index) {
      this.selectedCategory = index;
    },
    goTo(path, params) {
      this.$router.push({ name: path, params });
      this.isOpen = false;
    },
    goToFavorite() {
      this.goTo('profile', { tab: 3 });
      this.$store.commit('setIsVisible', true);
    },
    goToLogin() {
      this.goTo('login');
    },
    goToChats() {
      this.goTo('profile', { tab: 2 });
      this.$store.commit('setIsVisible', true);
    },
    goToProfile() {
      this.goTo('profile', { tab: 1 });
      this.$store.commit('setIsVisible', true);
    },
    goToCatalog(categoryId, subCategoryName, subCategoryId) {
      this.goTo('catalog', {
        categoryId,
        categoryName: this.categories[categoryId - 1].name_ru,
        subCategoryId,
        subCategoryName,
      });
    },
    goToArticle(id) {
      this.goTo('article', { tab: id });
    },
    handleSearchChange(val) {
      console.log(val, ' <<');
    },
    handleSearch(val) {
      this.goTo('catalog', { search: val });
    },
  },
};
</script>

<template>
  <div class="burger-menu">
    <div v-if="loading" class="burger-menu__layout">
      <Spinner/>
    </div>
    <div v-else class="burger-menu__layout" @click="toggleMenu">
      <img src="../../../assets/icons/base/menu-burger.svg" alt="?">
    </div>
    <div :class="[isOpen ? 'block' : 'hidden']">
      <div v-if="!isMobile" class="burger-menu__container" @mouseleave="toggleMenu">
        <div class="burger-menu__category-items">
          <div
              v-for="(category, index) in categories"
              :key="category.id"
              @mouseenter="selectCategory(index)"
          >
            <BurgerItem
                :id="category.id"
                :title="category.name_ru"
                :icon="category.photo_url"
                :selected="index === selectedCategory"
            />
          </div>
        </div>
        <div class="burger-menu__content">
          <div
              v-for="(subCategory, index) in filteredSubCategoryList"
              :key="index"
              @click="goToCatalog(subCategory?.category_id, subCategory.name_ru, subCategory.id)"
          >
            <BurgerItem :id="subCategory?.category_id" :title="subCategory.name_ru" :icon="subCategory.photo_url" isColumn />
          </div>
        </div>
      </div>
      <div v-else class="burger-menu__mobile-container">
        <div class="burger-menu__mobile-close" @click="toggleMenu">
          <Text type="h700w20px" label="Каталог"/>
          <img src="../../../assets/icons/base/cross.svg" alt="">
        </div>
        <div class="burger-menu__mobile-content">
          <div class="burger-menu__mobile-header">
            <div class="burger-menu__mobile-controls">
              <Button
                  v-if="isLoggedIn"
                  title="Профиль"
                  @click="goToProfile"
                  width="100%"
                  isImageFirst
              >
                <template #icon>
                  <img src="../../../assets/icons/base/profile.svg" alt="">
                </template>
              </Button>
              <Button
                  v-else
                  title="Войти"
                  @click="goToLogin"
                  color="primaryGreen"
                  width="100%"
              />
              <Button
                  title="0"
                  color="transparent"
                  @click="goToFavorite"
                  isImageFirst
              >
                <template #icon>
                  <img src="../../../assets/icons/base/heart.svg" alt="">
                </template>
              </Button>
            </div>
<!--            <SerchComponent-->
<!--                placeholder="Поиск товара по названию"-->
<!--                @onChange="handleSearchChange"-->
<!--                @onSearch="handleSearch"-->
<!--                :maxLength="44"-->
<!--            />-->
          </div>
          <div class="burger-menu__mobile-content-container" :style="{ height: contentHeight }">
            <div class="burger-menu__mobile-list">
              <BurgerItem
                  v-for="category in categories"
                  :key="category.id"
                  :id="category.id"
                  :title="category.name_ru"
                  :icon="category.photo_url"
                  :dropdownList="subCategoryList"
                  @dropdownClick="goToCatalog"
                  isDropdown
              />
            </div>
            <div class="burger-menu__mobile-footer">
              <div @click="goToArticle(1)">
                <Text type="t500w15px" label="Публичная оферта" link/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.burger-menu {
  &__layout {
    border: 1px solid var(--gray-F5);
    margin: 0 8px 0 28px;
    padding: 14px 13px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    &:hover {
      background-color: var(--gray-FB);
    }
    &:active {
      background-color: var(--gray-EE);
    }
  }

  &__container {
    display: flex;
    max-width: 720px;
    height: 336px;
    position: absolute;
    top: 152%;
    left: 39%;
    z-index: 1;
    box-shadow: 0 14px 16px -4px rgba(0, 0, 0, 0.2);
    opacity: 1;
  }

  &__category-items {
    display: flex;
    flex-direction: column;
    background-color: var(--gray-FB);
    border-radius: 0 0 0 8px;
    overflow: auto;
    width: 180px;
    padding: 8px 0;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--gray-DD);
      border-radius: 20px;
    }
  }

  &__content {
    display: flex;
    padding: 14px;
    background-color: var(--white-100);
    border-radius: 0 0 8px 0;
    width: 522px;
    gap: 10px;
    flex-wrap: wrap;
  }

  &__mobile-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--white-100);
  }

  &__mobile-close {
    display: flex;
    justify-content: space-between;
    padding: 14px;
    border-bottom: 1px solid var(--gray-F5);
  }

  &__mobile-content {
    overflow: auto;
    height: 100%;
  }

  &__mobile-header {
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 14px;
  }

  &__mobile-controls {
    display: flex;
    gap: 8px;
    width: 100%;
  }

  &__mobile-content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80vh;
  }

  &__mobile-list {
    padding: 0 14px;
  }

  &__mobile-footer {
    display: flex;
    padding: 28px 14px;
    gap: 14px;
    border-top: 1px solid var(--gray-F5);
  }


  @media (max-width: 1000px) {
    &__container {
      top: 161%;
      left: -120%;
    }
  }

  @media (max-width: 768px) {
    &__container {
      top: -180%;
      left: -10%;
      width: 100vw;
      max-width: unset;
      height: 100vh;
    }

    &__content {
      width: 100%;
    }

    &__layout {
      margin: 0;
    }
  }
}
</style>
