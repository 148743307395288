import httpClient from "@/services/http.service";
import errorHandler from "@/utils/plugins/errorHandler";

export const getProducts = async () => {
    try {
        const { data, status } = await httpClient.get('api/product/index');
        if (status === 200) {
            return { success: true, data: data.data };
        }
    } catch (error) {
        errorHandler(error.message);
        return { success: false, data: error.message };
    }
};

export const getFilteredProducts = async (
    searchName,
    categoryId,
    subCategoryId,
    filters,
    minPrice,
    maxPrice
) => {
    try {
        const filterParams = filters.map((filter) => `${filter.filterField}=${filter.filterId}`).join('&');

        const { data, status } = await httpClient.get(`api/product/index?${filterParams}`, {
            params: {
                name: searchName,
                category_id: Number(categoryId),
                subcategory_id: [subCategoryId],
                min_price: minPrice,
                max_price: maxPrice
            }
        });

        if (status === 200) {
            return { success: true, data: data.data };
        }
    } catch (error) {
        errorHandler(error.message);
        return { success: false, data: error.message };
    }
};

// Получение продукта по ID
export const getProduct = async (id) => {
    const url = `api/product/show/${id}`;
    try {
        const { data, status } = await httpClient.get(url);
        if (status === 200) {
            return { success: true, data: data.data };
        }
        return { success: false, data: "Unexpected status code" };
    } catch (error) {
        const errorMessage = error.message || "An error occurred";
        errorHandler(errorMessage);
        return { success: false, data: errorMessage };
    }
};

// Добавление продукта
export const addProduct = async (data) => {
    const url = `api/product/store`;
    try {
        const { data: responseData, status } = await httpClient.post(url, data);
        if (status === 200) {
            return { success: true, data: responseData.data };
        }
        return { success: false, data: "Unexpected status code" };
    } catch (error) {
        const errorMessage = error.message || "An error occurred";
        errorHandler(errorMessage);
        return { success: false, data: errorMessage };
    }
};

// Редактирование продукта
export const editProduct = async (data, id) => {
    const url = `api/product/update/${id}`;
    try {
        const { data: responseData, status } = await httpClient.post(url, data);
        if (status === 200) {
            return { success: true, data: responseData.data };
        }
        return { success: false, data: "Unexpected status code" };
    } catch (error) {
        const errorMessage = error.message || "An error occurred";
        errorHandler(errorMessage);
        return { success: false, data: errorMessage };
    }
};

// Удаление продукта
export const deleteProduct = async (id) => {
    const url = `api/product/delete/${id}`;
    try {
        const { data: responseData, status } = await httpClient.delete(url);
        if (status === 200) {
            return { success: true, data: responseData.data };
        }
        return { success: false, data: "Unexpected status code" };
    } catch (error) {
        const errorMessage = error.message || "An error occurred";
        errorHandler(errorMessage);
        return { success: false, data: errorMessage };
    }
};