// src/errorHandler.js

import store from '@/store';

const errorHandler = (error) => {
    store.dispatch('addNotification', { type: 'error', message: error });
    console.error('Global Error Handler:', error);
};

export default errorHandler;
