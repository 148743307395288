<template>
  <Header/>
  <div class="container app-container">
    <div class="app-wrapper">
      <router-view/>
    </div>
  </div>
  <Footer/>
  <LoginModal/>
</template>

<script>

import Header from "@/components/header/Header.vue";
import Footer from "@/components/footer/Footer.vue";
import LoginModal from "@/components/base/login-modal/LoginModal.vue";
import {mapActions, mapState} from "vuex";

export default {
  name: 'HomeView',
  components: {LoginModal, Footer, Header},
  computed:{
    ...mapState(['isLoggedIn']),
    ...mapState('favorite', ['favorite_form']),
    ...mapState('order', ['order_form']),
  },
  methods:{
    ...mapActions('favorite', ['fetchFavorite']),
    ...mapActions('order', ['fetchOrders']),
  },
  async mounted(){
    if (!this.favorite_form.list.length && this.isLoggedIn){
      await this.fetchFavorite()
    }
    if (!this.order_form.list.length && this.isLoggedIn){
      await this.fetchOrders()
    }
  }
}
</script>

<style scoped>
.app-container{
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 427px);
}

.app-wrapper{
  width: 100%;
}
</style>
