import httpClient from "@/services/http.service";
import errorHandler from "@/utils/plugins/errorHandler";

export async function getSliders() {
    try {
        const { data, status } = await httpClient.get('api/desktop-banner/index');
        if (status === 200) {
            return { success: true, data: data.data };
        }
    } catch (error) {
        errorHandler(error.response.data.message);
        return { success: false, data: null };
    }
}

export async function addSlider(image) {
    const formData = new FormData();
    formData.append('image', image);

    try {
        const { data, status } = await httpClient.post('api/desktop-banner/store', formData);
        if (status === 200) {
            return { success: true, data: data.data };
        }
    } catch (error) {
        errorHandler(error.response.data.message);
        return { success: false, data: null };
    }
}

export async function editSlider(data, id) {
    try {
        const { data, status } = await httpClient.post(`api/desktop-banner/update/${id}`, data);
        if (status === 200) {
            return { success: true, data: data.data };
        }
    } catch (error) {
        errorHandler(error.response.data.message);
        return { success: false, data: null };
    }
}

export async function deleteSlider(id) {
    try {
        const { data, status } = await httpClient.delete(`api/desktop-banner/delete/${id}`);
        if (status === 200) {
            return { success: true, data: data.data };
        }
    } catch (error) {
        errorHandler(error.response.data.message);
        return { success: false, data: null };
    }
}
