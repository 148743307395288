<template>
  <div class="login-form">
    <div class="login-form__header">
      <div class="login-form__header-text">
        <Text type="h700w20px" color="green" label="Добро пожаловать на"/>
        <img src="@/assets/images/logos/logo.svg" alt="?">
      </div>
      <Text type="t500w15px" :label="codeSent ? 'Введите код подтверждения' : 'Введите номер телефона для продолжения'" />
    </div>
    <Spinner v-if="codeSending" />
    <div v-else>
      <div v-if="codeSent" class="login-form__description">
        <CodeInput :phone="phone"/>
        <Text :type="'t600w15px'" :label="`Код выслан на ${Number(phone) ? '+7' : ''}${phone}`" />
        <Button color="primaryGreen" @click="stepBack()">
          <template #icon>
            <BaseIcon width="16" height="16" name="leftArrow" color="#fff"/>
          </template>
        </Button>
      </div>
      <form v-else class="login-form__container" @submit="handleSend">
        <PhoneInput v-model="phone" :onUpdateError="updateError" :onUpdateIsPhoneCorrect="updateIsPhoneCorrect" />
        <div class="login-form__field-stay-login">
          <Checkbox label="Оставаться в системе" color="gray" />
        </div>
        <div class="login-form__field-controls">
          <Button width="100%" color="primaryGreen" title="Получить код по СМС" :disabled="!isPhoneCorrect"/>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Text from "@/components/base/text/Text.vue";
import PhoneInput from "@/components/base/phoneInput/PhoneInput.vue";
import Button from "@/components/base/button/Button.vue";
import Checkbox from "@/components/base/checkbox/Checkbox.vue";
import CodeInput from "@/components/base/codeInput/CodeInput.vue";
import BaseIcon from "@/components/base/base-icon/BaseIcon.vue";
import authService from "@/services/auth.service";
import Spinner from "@/components/base/spinner/Spinner.vue";

export default {
  components: {
    Spinner,
    BaseIcon,
    CodeInput,
    Checkbox,
    Button,
    PhoneInput,
    Text
  },
  data() {
    return {
      error: false,
      isPhoneCorrect: false,
      phone: '',
      codeSent: false,
      codeSending: false
    };
  },
  methods: {
    async handleSend(event) {
      event.preventDefault();
      this.codeSending = true
      let res = await authService.register(`7${this.phone}`)
      if (res.success){
        this.codeSent = true
      }
      this.codeSending = false
    },
    updateError(newError) {
      this.error = newError;
    },
    updateIsPhoneCorrect(newIsPhoneCorrect) {
      this.isPhoneCorrect = newIsPhoneCorrect;
    },
    stepBack(){
      this.error = false
      this.isPhoneCorrect = false
      this.phone = ''
      this.codeSent = false
    }
  }
};
</script>

<style lang="scss">
.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  gap: 40px;
  max-width: 476px;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
  }

  &__header-text {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }

  &__field-stay-login {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__field-controls {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__description {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    margin-top: 15px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;

    &-links {
      display: flex;
      gap: 28px;
    }
  }

  @media (max-width: 1530px) {
    &__footer {
      flex-direction: column;
      align-items: center;
    }
  }

  @media (max-width: 1385px) {
    &__footer-links {
      flex-direction: column;
      gap: 10px;
      margin-bottom: 10px;
    }
  }

  @media (max-width: 500px) {
    &__header-text {
      flex-direction: column;
    }
    &__header {
      text-align: center;
    }
  }
}
</style>
