import httpClient from "@/services/http.service";
import errorHandler from "@/utils/plugins/errorHandler";

export const getBrands = async () => {
    try {
        const { data, status } = await httpClient.get('api/brand/index');
        if (status === 200) {
            return { success: true, data: data.data };
        }
    } catch (error) {
        errorHandler(error.message);
        return { success: false, data: error.message };
    }
};

export const getFilteredBrands = async (category_id, subcategory_id) => {
    try {
        const { data, status } = await httpClient.get('api/brand/index', {
            params: {
                category_id: Number(category_id),
                subcategory_id: [subcategory_id],
            }
        });
        if (status === 200) {
            return { success: true, data: data.data };
        }
    } catch (error) {
        errorHandler(error.message);
        return { success: false, data: error.message };
    }
};

export const addBrand = async (data) => {
    try {
        const { data: responseData, status } = await httpClient.post('api/brand/store', data);
        if (status === 200) {
            return { success: true, data: responseData.data };
        }
    } catch (error) {
        errorHandler(error.message);
        return { success: false, data: error.message };
    }
};

export const editBrand = async (id, data) => {
    try {
        const { data: responseData, status } = await httpClient.post(`api/brand/update/${id}`, data);
        if (status === 200) {
            return { success: true, data: responseData.data };
        }
    } catch (error) {
        errorHandler(error.message);
        return { success: false, data: error.message };
    }
};

export const deleteBrand = async (id) => {
    try {
        const { data: responseData, status } = await httpClient.delete(`api/brand/delete/${id}`);
        if (status === 200) {
            return { success: true, data: responseData.data };
        }
    } catch (error) {
        errorHandler(error.message);
        return { success: false, data: error.message };
    }
};
