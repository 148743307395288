import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from "@/views/LoginView.vue";
import MainView from "@/views/main/MainView.vue";
import ProfileView from "@/views/profile/ProfileView.vue";
import CategoryView from "@/views/category/CategoryView.vue";
import OrderView from "@/views/order/OrderView.vue";

const routes = [
  {
    path: '/',
    name: 'default',
    redirect: '/home',
    component: HomeView,
    children: [
      {
        path: 'home',
        name: 'home',
        component: MainView
      },
      {
        path: 'profile/:tab',
        name: 'profile',
        component: ProfileView
      },
      {
        path: 'category/:id',
        name: 'category',
        component: CategoryView
      },
      {
        path: 'order/:orderId',
        name: 'order',
        component: OrderView
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
