import httpClient from "@/services/http.service";
import errorHandler from "@/utils/plugins/errorHandler";

export async function getDiscountProducts() {
    try {
        const { data, status } = await httpClient.get('api/product/get-discounts');
        if (status === 200) {
            return { success: true, data: data.data };
        }
    } catch (error) {
        errorHandler(error.message);
        return { success: false, data: null };
    }
}