<template>
  <div class="header-component">
    <div class="header-component__wrapper">
      <div class="header-component__controls-row">
        <div class="header-component__controls">
          <div class="header-component__link">
            <img src="../../assets/icons/base/city.svg" alt="?"/>
            <Text label="Актобе" type="t400w15px"/>
          </div>
          <div class="header-component__link">
            <img src="../../assets/icons/base/geolocation.svg" alt="?"/>
            <Text label="Улица Сатпаева" type="t400w15px"/>
          </div>
          <div class="header-component__link-time">
            <img src="../../assets/icons/base/clock.svg" alt="?"/>
            <Text :label="nowDateTime" type="t400w15px"/>
          </div>
        </div>
        <div class="header-component__lang">
          <Text label="Рус" type="t400w15px"/>
          <img src="../../assets/icons/base/down-arrow.svg" alt="?">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Text from "@/components/base/text/Text.vue";

export default {
  name: 'HeaderComponent',
  components: {Text},
  data() {
    return {
      nowDateTime: this.getNowDateTime()
    };
  },
  methods: {
    getNowDateTime() {
      const date = new Date();
      return date.toLocaleString('ru-RU', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      });
    },
    updateDateTime() {
      this.nowDateTime = this.getNowDateTime();
    }
  },
  mounted() {
    this.interval = setInterval(this.updateDateTime, 60000); // Обновление каждую минуту
  },
  beforeDestroy() {
    clearInterval(this.interval); // Очистка интервала при уничтожении компонента
  }
};
</script>

<style lang="scss">
.header-component {
  display: flex;
  justify-content: center;
  background-color: var(--gray-F5);
  z-index: 2;
  position: relative;

  &__wrapper {
    max-width: 1304px;
    width: 100%;
  }

  &__controls-row {
    display: flex;
    justify-content: space-between;
  }

  &__controls {
    display: flex;
    gap: 8px;
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 10px;
    margin: 7px 0;
  }

  &__link-time{
    display: none;
  }

  &__lang {
    display: flex;
    align-items: center;
    gap: 2px;
    padding: 10px;
  }

  @media (min-width: 768px) {
    &__link-time {
      display: flex;
      align-items: center;
      gap: 6px;
      padding: 10px;
      margin: 7px 0;
    }

    &__lang {
      padding: 0;
    }
  }
}
</style>
