<template>
  <div class="catalog-card">
    <div class="catalog-card__container" @click="handleClick">
      <img class="catalog-card__image" v-if="image" :src="`${apiURL}${image}`" :alt="title" />
      <div v-else class="catalog-card__image"></div>
      <div class="catalog-card__content">
        <Text type="t600w15px" :label="title"/>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CatalogCard',
  props: {
    id: {
      type: Number,
      required: true
    },
    image: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      apiURL: process.env.VUE_APP_API_URL
    };
  },
  methods: {
    handleClick() {
      this.$router.push(`/category/${this.id}`);
    }
  }
};
</script>

<style scoped>
.catalog-card__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 14px;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  width: 144px;
  height: 140px;
  gap: 8px;
  cursor: pointer;

  &:hover {
    border-color: #dcdcdc;
  }
}

.catalog-card__image {
  width: 48px;
  min-height: 48px;
  background-color: #eeeeee;
  border-radius: 8px;
}

.catalog-card__content {
  text-align: center;
  max-width: 116px;
}

@media (max-width: 900px) {
  .catalog-card{
    height: 120px;
    width: 120px;
  }
  .catalog-card__container {
    padding: 15px 5px;
    gap: 7px;
    max-height: 120px;
    min-width: 130px;
    align-self: flex-start;
  }

  .catalog-card__content {
    text-align: center;
  }

  .catalog-card__image {
    width: 48px;
    height: 48px;
    background-color: #eeeeee;
    border-radius: 8px;
  }
}
</style>
