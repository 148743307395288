<template>
  <div class="catalog-card-skeleton">
    <div class="catalog-card-skeleton__wrapper">
      <div class="catalog-card-skeleton__skeleton"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CatalogCardSkeleton",
};
</script>

<style lang="scss">
.catalog-card-skeleton {
  &__wrapper{
    position: relative;
    width: 144px;
    height: 140px;
  }

  &__skeleton {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    animation: shimmer 2s infinite;
    z-index: 2;
    pointer-events: none;

    &--hidden {
      display: none;
    }

    @keyframes shimmer {
      0% {
        background-position: -200% 0;
      }
      100% {
        background-position: 200% 0;
      }
    }
    background: linear-gradient(
            to right,
            #e0e0e0 0%,
            #f0f0f0 50%,
            #e0e0e0 100%
    );
    background-size: 200% 100%;
  }
}
</style>
