<script>
export default {
  name: "CatalogView"
}
</script>

<template>

</template>

<style scoped lang="scss">

</style>