<template>
  <p :class="computedClass">
    {{label}}
    <slot></slot>
  </p>
</template>

<script>
export default {
  name: 'Text',
  props: {
    type: {
      type: String,
      required: true,
      validator(value) {
        return [
          'h700w24px', 'h700w40px', 'h700w20px', 'h500w24px',
          't600w17px', 't400w17px', 't600w15px', 't500w15px',
          't400w15px', 't600w13px', 't500w13px', 't400w13px',
          't500w12px', 't400w12px', 't500w10px',  't500w16px',
        ].includes(value);
      }
    },
    color: {
      type: String,
      default: null,
      validator(value) {
        return ['black', 'green', 'gray', 'white', 'red'].includes(value);
      }
    },
    label: {
      type: String,
      default: ''
    },
    link: {
      type: Boolean,
      default: false
    },
    isThrough: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    computedClass() {
      return [
        'font',
        this.type,
        'text-'+this.color,
        { link: this.link, through: this.isThrough }
      ];
    }
  }
};
</script>

<style>
</style>
