<template>
  <div class="phone-input">
    <div :class="['phone-input__container', { 'phone-input__container--error': showError }]">
      <div class="phone-input__country-code">
        <Text type="t500w15px" label="+7" />
      </div>
      <input
          class="phone-input__input"
          placeholder="___ ___ __ __"
          @input="handleChange"
          :value="formattedValue"
          maxlength="13"
          required
          inputmode="numeric"
          type="text"
      />
    </div>
    <div v-if="showError" class="phone-input__error-container">
      <Text type="t500w12px" label="Invalid phone number" color="red"/>
    </div>
  </div>
</template>

<script>
import Text from "@/components/base/text/Text.vue";

export default {
  name: 'PhoneInput',
  components: { Text },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    onUpdateError: {
      type: Function,
      required: true
    },
    onUpdateIsPhoneCorrect: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      value: this.modelValue,
      showError: false
    };
  },
  computed: {
    formattedValue() {
      const rawValue = this.value.replace(/[^\d]/g, '');
      return rawValue.replace(/(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/, (_, p1, p2, p3, p4) => {
        return [p1, p2, p3, p4].filter(Boolean).join(' ');
      });
    }
  },
  watch: {
    value(newValue) {
      this.$emit('update:modelValue', newValue);
    },
    modelValue(newValue) {
      this.value = newValue;
    }
  },
  methods: {
    handleChange(e) {
      const input = e.target.value;
      const rawValue = input.replace(/[^\d]/g, '');
      this.value = rawValue;
      this.$emit('update:modelValue', rawValue);

      if (rawValue.length <= 10) {
        this.showError = false;
        this.onUpdateError(false);
      } else {
        this.triggerError();
      }

      if (rawValue.length === 10) {
        this.onUpdateIsPhoneCorrect(true);
      } else {
        this.onUpdateIsPhoneCorrect(false);
      }
    },
    triggerError() {
      this.showError = true;
      this.onUpdateError(true);
      setTimeout(() => {
        this.showError = false;
        this.onUpdateError(false);
        if (this.value.length === 10) {
          this.onUpdateIsPhoneCorrect(true);
        } else {
          this.onUpdateIsPhoneCorrect(false);
        }
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
.phone-input {
  &__container {
    display: flex;
    background-color: var(--gray-DD);
    border: 1px solid var(--gray-DD);
    border-radius: 8px;

    &--error {
      border-color: var(--red-100);
    }
  }

  &__country-code {
    padding: 13px 14px;
  }

  &__input {
    border: 0;
    background-color: var(--white-100);
    width: calc(100% - 26px);
    padding: 13px;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings: "slnt" 0;
    font-weight: 500;
    font-size: 15px;
    color: var(--gray-4F);
    border-radius: 0 8px 8px 0;

    &::placeholder {
      font-family: "Inter", sans-serif;
      font-optical-sizing: auto;
      font-style: normal;
      font-variation-settings: "slnt" 0;
      font-weight: 500;
      font-size: 15px;
    }

    &:focus {
      outline: none;
      border-color: var(--blue-50);
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    -moz-appearance: textfield;
  }

  &__error-container {
    padding-left: 14px;
    color: var(--red-100);
  }
}
</style>
